import React from "react";
import { messaging } from "./init-fcm";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "./_helpers";
import { alertActions } from "./_actions";
import { PrivateRoute } from "./_components";
import { PrivateRouteX } from "./_components";
import { AdminRoute } from "./_components";
import routes from "./routes";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { store } from "./_helpers";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const renderNotification = (notification, i) => <li key={i}>{notification}</li>;

const registerPushListener = pushNotification =>
  navigator.serviceWorker.addEventListener("message", ({ data }) => {
    console.log(data);
  if(data.data) {
    console.log(data.data.message);
    console.log('f')
    if (Notification.permission === "granted") {
      // If it's okay let's create a notification
      var notification = new Notification(data.data.message);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          var notification = new Notification(data.data.message);
        }
      });
    }
    }else{

      if (Notification.permission === "granted") {
        // If it's okay let's create a notification
        var notification = new Notification(data["firebase-messaging-msg-data"].notification.title, {body: data["firebase-messaging-msg-data"].notification.body});
      }
  
      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            var notification = new Notification(data["firebase-messaging-msg-data"].notification.title, {body: data["firebase-messaging-msg-data"].notification.body});
          }
        });
      }
    }
}
   
  );

class App extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      // this.props.clearAlerts();
    });
  }

  componentDidMount() {
    // Antes de realizar cualquier acción, valido el token
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status === 401) {
          // Elimino el storage y el dealer
          localStorage.removeItem("user");
          localStorage.removeItem("permission");
          localStorage.removeItem("user2");
          localStorage.removeItem("dealer_list");
          cookies.remove("dealer");
          // Redirecciono al login
          window.location.replace("/");
        }
        throw error;
      }
    );
  }

  render() {
    const { alert } = this.props;
    return (
      <Router history={history}>
        <div>
          <PrivateRouteX
            path="/"
            exact={true}
            component={props => {
              return <Redirect to="/dashboard" />;
            }}
          />

          <PrivateRoute
            path="/logout"
            exact={true}
            component={props => {
              localStorage.removeItem("user");
              localStorage.removeItem("user2");
              cookies.remove("dealer");
              return <Redirect to="/" />;
            }}
          />

          {routes.map((route, index) => {
            if (route.admin == true) {
              return (
                <AdminRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  categoria={route.categoria}
                  permiso={route.permiso}
                  component={props => {
                    return (
                      <route.layout {...props}>
                        {alert.message && (
                          <div className={`alert ${alert.type}`}>
                            {alert.message}
                          </div>
                        )}
                        <route.component {...props} />
                      </route.layout>
                    );
                  }}
                />
              );
            } else {
              if (route.private == true) {
                return (
                  <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={props => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>
                      );
                    }}
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={props => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>
                      );
                    }}
                  />
                );
              }
            }
          })}
        </div>
      </Router>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}
const actionCreators = {
  //clearAlerts: alertActions.clear
};

const connectedApp = connect(
  mapState,
  actionCreators
)(App);

export default compose(
  lifecycle({
    async componentDidMount() {
      const { pushNotification, setToken } = this.props;

      Notification.requestPermission().then(async function(result) {
            if (result === 'denied' || result === 'default') {
              console.log('Permission wasn\'t granted. Allow a retry.');
              return;
            }
            const token = await messaging.getToken();
            localStorage.setItem('push_token', token); 
          })
          .catch(function(err) {
            console.log("Unable to get permission to notify.", err);
          });


      registerPushListener(pushNotification);
    }
  })
)(connectedApp);