import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Divider, Tag } from "antd";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardHeader,
  CardBody,
  Button,
  CardTitle,
  CardSubtitle
} from "shards-react";
import { store } from "../_helpers";
import PageTitle from "../_components/utils/PageTitle";
import Cookies from "universal-cookie";
import "../RankingSociosPage/RankingSociosPage.css";
import { API_URL } from "../_config";

class RankingSociosPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      response: {},
      agencias: [],
      agencias_historico: [],
      marcas: [],
      modelos: [],
      historico: false,
      open: false,
      intents: 0,
      primer_lugar: "Cargando...",
      segundo_lugar: "Cargando...",
      tercer_lugar: "Cargando..."
    };

    this.catchSelect = this.catchSelect.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  componentWillMount() {
    axios
      .get(
        API_URL + "dashboard?token=" +
          store.getState().authentication.user.token
      )
      .then(response => {
        if (response.data.top_agencias == "") {
          this.setState({
            response: response.data,
            agencias_historico: response.data.top_agencias_historicos,
            marcas: response.data.top_marcas,
            modelos: response.data.top_modelos
          });
        } else {
          this.setState({
            response: response.data,
            agencias: response.data.top_agencias,
            agencias_historico: response.data.top_agencias_historicos,
            marcas: response.data.top_marcas,
            modelos: response.data.top_modelos
          });
        }

        this.state.agencias.map((item, i) => {
          if (this.state.intents == 3) {
            // Overrated
          } else {
            if (this.state.intents == 0) {
              this.setState({
                primer_lugar: item.agencia
              });
            }
            if (this.state.intents == 1) {
              this.setState({
                segundo_lugar: item.agencia
              });
            }

            if (this.state.intents == 2) {
              this.setState({
                tercer_lugar: item.agencia
              });
            }

            this.setState(prevState => {
              return { intents: prevState.intents + 1 };
            });
          }
        });
      });
  }

  toggle() {
    this.setState(prevState => {
      return { open: !prevState.open };
    });
  }

  catchSelect(event) {
    if (event.target.value == 1) {
      this.setState({
        historico: true
      });
    } else {
      this.setState({
        historico: false
      });
    }
  }

  render() {
    const columns = [
      {
        title: "Posición",
        dataIndex: "posicion",
        key: "posicion",
        render: text => <a>{text}</a>
      },
      {
        title: "Ranking",
        dataIndex: "ranking",
        key: "ranking",

        sorter: (a, b) => a.ranking.localeCompare(b.ranking)
      },
      {
        title: "Este Mes",
        dataIndex: "estemes",
        key: "estemes",

        sorter: (a, b) => a.estemes - b.estemes
      },
      {
        title: "Variación",
        dataIndex: "variacion",
        key: "variacion",

        sorter: (a, b) => a.variacion.porcen - b.variacion.porcen,
        render: text => (
          <>
            <i class={"icono-arrow1-down " + text.arrow}></i> {text.porcen} %
          </>
        )
      },
      {
        title: "Última publicación",
        dataIndex: "last",
        key: "last",
        sorter: (a, b) => new Date(a.last) - new Date(b.last)
      },
      {
        title: "Total publicados",
        dataIndex: "total",
        key: "total",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.total - b.total
      }
    ];

    var data = [];

    if (this.state.historico == false) {
      var data = [
        this.state.agencias.map((item, i) => ({
          posicion: i + 1,
          ranking: item.agencia,
          estemes: item.publicacion_mes_actual,
          variacion: { arrow: item.porcen.arrow, porcen: item.porcen.t },
          last: item.ultima_publicacion,
          total: item.total_public
        }))
      ];
    } else {
      var data = [
        this.state.agencias_historico.map((item, i) => ({
          posicion: i + 1,
          ranking: item.agencia,
          estemes: item.publicacion_mes_actual,
          variacion: { arrow: item.porcen.arrow, porcen: item.porcen.t },
          last: item.ultima_publicacion,
          total: item.total_public
        }))
      ];
    }
    console.log(data[0]);
    return (
      <>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Ranking Socios"
            className="text-sm-left"
            style={{ paddingTop: "5px", paddingBottom: "5px" }}
          />
          <Col sm="4"></Col>
          <Col sm="4">
            <Row className="noprinter">
              <Col sm="6" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                <Dropdown open={this.state.open} toggle={this.toggle}>
                  <DropdownToggle theme="primary">Exportar</DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>
                      <a
                        href={API_URL + "api/ranking_socios/export_excel_mes_actual"}
                        target="_blank"
                      >
                        Exportar a Excel Mes Actual
                      </a>
                    </DropdownItem>
                    <DropdownItem>
                      <a
                        href={API_URL + "api/ranking_socios/export_to_excel"}
                        target="_blank"
                      >
                        Exportar a Excel Historico
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>

              <Col sm="6" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                <Button onClick={() => window.print()} theme="secondary">
                  Imprimir
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {this.state.primer_lugar != "Cargando..." && (
          <Row>
            <Col sm="4" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
              <Card>
                <CardBody style={{ textAlign: "center" }}>
                  <br />
                  <br />
                  <h1 style={{ fontSize: "6.052rem", color: "#1f1f1f" }}>1°</h1>
                  <br />
                  <CardTitle>{this.state.primer_lugar}</CardTitle>
                  <br />

                  <br />
                  <br />
                </CardBody>
              </Card>
            </Col>
            <Col sm="4" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
              <Card>
                <CardBody style={{ textAlign: "center" }}>
                  <br />
                  <br />
                  <h1 style={{ fontSize: "4.052rem", color: "#1f1f1f" }}>2°</h1>
                  <br />
                  <CardTitle>{this.state.segundo_lugar}</CardTitle>
                  <br />

                  <br />
                  <br />
                </CardBody>
              </Card>
            </Col>
            <Col sm="4" style={{ paddingTop: "15px", paddingBottom: "15px" }}>
              <Card>
                <CardBody style={{ textAlign: "center" }}>
                  <br />
                  <br />
                  <h1 style={{ fontSize: "3.052rem", color: "#1f1f1f" }}>3°</h1>
                  <br />
                  <CardTitle>{this.state.tercer_lugar}</CardTitle>
                  <br />

                  <br />
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        <br />
        <Card>
          <CardBody style={{ fontWeight: "bold", color: "black" }}>
            TOP RANKING CLIENTES SEGUN USO:{" "}
            <select onChange={this.catchSelect}>
              <option value={0} defaultChecked>
                Mes Actual
              </option>
              <option value={1}>Histórico</option>
            </select>
            <br />
            <br />
            <div
              class="table-responsive"
              style={{ color: "rgba(0, 0, 0, 0.65)", fontWeight: "normal" }}
            >
              <Table
                columns={columns}
                dataSource={data[0]}
                pagination={{ pageSize: 20 }}
                defaultSorting={[{ id: "total", desc: true }]}
              />
            </div>
          </CardBody>
        </Card>

        <br />

        <Row>
          <Col sm="6" className="pb-4">
            <Card>
              <CardBody style={{ fontWeight: "bold", color: "black" }}>
                TOP 5 RANKING MARCAS MAS VENDIDAS:
                <br />
                <br />
                <div
                  class="table-responsive"
                  style={{ color: "rgba(0, 0, 0, 0.65)", fontWeight: "normal" }}
                >
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th>Posicion</th>
                        <th>Marca</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.marcas.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" className="pb-4">
            <Card>
              <CardBody
                style={{
                  fontWeight: "bold",
                  color: "black",
                  marginBottom: "10px"
                }}
              >
                TOP 10 RANKING MODELOS MAS VENDIDOS:
                <br />
                <br />
                <div
                  class="table-responsive"
                  style={{ color: "rgba(0, 0, 0, 0.65)", fontWeight: "normal" }}
                >
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th>Posicion</th>
                        <th>Modelo</th>
                        <th>Marca</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.modelos.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.modelo}</td>
                          <td>{item.marca}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}
const actionCreators = {};

const connectedDashboard = connect(
  mapState,
  actionCreators
)(RankingSociosPage);
export { connectedDashboard as RankingSociosPage };
