import React, { useEffect } from "react";
import { Field, reduxForm, reset } from "redux-form";
import validate from "../../_common/ValidatePublishSecond";
import SelectSimple from "../../_common/SelectSimple";
import InputField from "../../_common/InputField";
import {
  Row,
  Col,
  Button,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  Form,
  FormGroup,
  FormInput,
  FormSelect
} from "shards-react";

const number = value =>
  value && isNaN(Number(value)) ? "Solo puede contener números" : undefined;
const required = value => {
  if (value) {
    return undefined;
  } else {
    return "Ese campo es requerido";
  }
};
const renderError = ({ meta: { touched, error } }) =>
  touched && error ? <span>{error}</span> : false;

function WizardFormSecondPage(props) {
  const {
    handleSubmit,
    pristine,
    previousPage,
    submitting,
    onClose,
    checkData
  } = props;
  const [defaultValue, setDefaultValue] = React.useState(false);
  console.log(checkData, "Props");

  useEffect(() => {
    return () => {
      props.dispatch(reset("publisVehicules"));
    };
  }, []);

  return (
    <form>
      <div className="row">
        <div className="col-md-8">
          <FormGroup>
            <Field
              name="price"
              type="text"
              validate={[required, number]}
              label="Precio"
              component={InputField}
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="chasis_number"
              label="Nº de Chasis"
              type="text"
              component={InputField}
            />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup>
            <Field
              validate={[required]}
              name="currency_id"
              children={[[1, "ARS"], [2, "USD"]]}
              component={SelectSimple}
              label="Moneda (*)"
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="kilometers"
              label="Kilometros (*)"
              type="number"
              validate={[required, number]}
              component={InputField}
            />
          </FormGroup>
        </div>
      </div>
      <Row>
        <div className="col-md-4">
          <FormGroup>
            <Field
              name="year"
              validate={[required, number]}
              type="text"
              label="Año (*)"
              component={InputField}
            />
          </FormGroup>
        </div>
        <div className="col-md-4">
          <FormGroup>
            <Field
              name="doors"
              validate={[required]}
              label="Cant. de puertas (*)"
              children={[[2, 2], [3, 3], [4, 4], [5, 5]]}
              component={SelectSimple}
            />
          </FormGroup>
        </div>

        <div className="col-md-4">
          <FormGroup>
            <Field
              validate={[required]}
              name="type_vehicle_fuel_id"
              children={[[2, "Diésel"], [1, "Nafta"], [3, "Nafta/Gnc"]]}
              component={SelectSimple}
              label="Combustible (*)"
            />
          </FormGroup>
        </div>
      </Row>

      <FormGroup>
        <Field
          name="owner"
          label="Títular"
          type="text"
          component={InputField}
        />
      </FormGroup>

      <FormGroup>
        <Field
          name="direction"
          validate={[required]}
          children={[
            ["Mecánica", "Mecánica"],
            ["Hidráulica", "Hidráulica"],
            ["Asistida", "Asistida"],
            ["Eléctrica", "Eléctrica"]
          ]}
          component={SelectSimple}
          label="Dirección (*)"
        />
      </FormGroup>

      <FormGroup>
        <Field
          name="transmission"
          validate={[required]}
          children={[
            ["CVT", "CVT"],
            ["Secuencial", "Secuencial"],
            ["Manual", "Manual"],
            ["Automática", "Automática"],
            ["N/E Autos", "N/E Autos"]
          ]}
          component={SelectSimple}
          label="Transmisión (*)"
        />
      </FormGroup>

      <FormGroup>
        <Field
          name="heating"
          children={[
            ["N/E", "N/E"],
            ["Doble aire", "Doble aire"],
            ["Calefacción", "Calefacción"],
            ["Aire acondicionado", "Aire acondicionado"]
          ]}
          component={SelectSimple}
          label="Climatización"
        />
      </FormGroup>

      <Row>
        <Col sm="4">
          <Button type="submit" className="mt-4" active onClick={previousPage}>
            Anterior
          </Button>
        </Col>
        <Col sm="4"></Col>
        <Col sm="4">
          <Button
            onClick={() => {
              return handleSubmit(), setDefaultValue(true);

              // setTimeout(() => {
              //   onClose()
              // }, 2500);
            }}
            type="submit"
            disabled={checkData.syncErrors ? true : false || defaultValue}
            className="float-right mt-4"
          >
            Publicar
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default reduxForm({
  form: "publisVehicules", //                 <------ same form name
  validate,
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(WizardFormSecondPage);
