import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";
import Cookies from "universal-cookie";
import { history } from "../../../../_helpers";
import "./style.css";

const cookies = new Cookies();
const USER = JSON.parse(localStorage.getItem("user"));
//console.log("||VIEJ", USER.user.first_name);

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);
    if (
      !cookies.get("dealer") &&
      history.location.pathname != "/select_dealer"
    ) {
      console.log("current history" + history.location);
      history.push("/select_dealer");
    }
    this.state = {
      visible: false,
      open: false,
      user: null
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const { open } = this.state;

    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <Modal size="sm" open={open} toggle={() => console.log("Toggle")}>
          <ModalHeader>
            Ayuda Chat en Vivo
            <i
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                fontSize: "25px",
                cursor: "pointer"
              }}
              class="material-icons"
              onClick={this.toggle}
            >
              clear
            </i>
          </ModalHeader>
          <ModalBody style={{ height: "420px", padding: "0px" }}>
            <iframe
              src="https://mesadeayuda.cca.org.ar/index.php?p=start&sp=1&ssp=es&sssp=1&ssssp=none"
              frameborder="0"
              style={{ width: "100%", marginTop: "5px", height: "100%" }}
            ></iframe>
          </ModalBody>
        </Modal>

        <DropdownToggle
          caret
          tag={NavLink}
          className="text-nowrap px-3"
          style={{
            margin: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%"
          }}
        >
          {cookies.get("dealer") &&
            (typeof cookies.get("dealer").dealerLogo == "string" ? (
              <div
                style={{
                  maxWidth: "6.5rem",
                  maxHeight: "2.5rem",
                  borderRadius: "6px",
                  border: "1px solid #eee",
                  marginRight: "7px",
                  display: "inline"
                }}
                dangerouslySetInnerHTML={{
                  __html: cookies.get("dealer").dealerLogo
                }}
              ></div>
            ) : (
              <div
                className="cardealertext"
                style={{
                  display: "inline"
                }}
              >
                <h5
                  className="cardealertext2"
                  style={{
                    fontSize: "97%",
                    margin: 0,
                    display: "inline-block"
                  }}
                >
                  {cookies.get("dealer").name}
                </h5>
              </div>
            ))}
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} id="profile" to="/miperfil">
            <i className="material-icons">person</i> Mi Perfil
            {USER && (
              <a className="nav-link text-nowrap px-3" href="#">
                {USER.user.cropped_image_url ? (
                  <img
                    className="user-avatar rounded-circle mr-2"
                    src={USER.user.cropped_image_url}
                    alt="User Avatar"
                  />
                ) : (
                  <img
                    className="user-avatar rounded-circle mr-2"
                    src={"/user.png"}
                    alt="User Avatar"
                  />
                )}
                <span className="d-none d-md-inline-block">
                  {USER.user.first_name}
                </span>
              </a>
            )}
          </DropdownItem>
          <DropdownItem tag={Link} to="/mydealer">
            <i className="material-icons">directions_car</i> Mi Concesionaria
          </DropdownItem>
          {cookies.get("dealer") && (
            <>
              {cookies.get("dealer").dealerUrl && (
                <a
                  tabindex="0"
                  target="_blank"
                  className="dropdown-item"
                  href={
                    "https://" +
                    cookies.get("dealer").dealerUrl +
                    ".nuestrosautos.cca.org.ar/"
                  }
                >
                  <i class="material-icons">pages</i> Mi página
                </a>
              )}
            </>
          )}
          <DropdownItem divider />

          <DropdownItem tag={Link} to="/vinculaciones">
            <i className="material-icons">link</i> Vinculaciones
          </DropdownItem>

          <DropdownItem divider />

          <DropdownItem tag={Link} to="/consultas">
            <i className="material-icons">message</i> Consultas
          </DropdownItem>

          <DropdownItem divider />

          <DropdownItem onClick={this.toggle}>
            <i className="material-icons">live_help</i> Ayuda Chat en Vivo
          </DropdownItem>

          <a
            tabindex="0"
            target="_blank"
            class="dropdown-item"
            href="https://mesadeayuda.cca.org.ar/index.php?p=faq&lang=esp&small=1&web=1"
          >
            <i className="material-icons">find_in_page</i> FAQ
          </a>
          <a
            tabindex="0"
            target="_blank"
            class="dropdown-item"
            href="https://mesadeayuda.cca.org.ar/index.php?p=support&amp;sp=create&amp;ssp=1&amp;lang=esp&amp;small=1&amp;web=1"
          >
            <i class="material-icons">store</i> Contacto Soporte
          </a>

          <Link to="/select_dealer" className="dropdown-item">
            <i class="material-icons">autorenew</i> Cambiar Concesionaria
          </Link>

          <DropdownItem divider />

          <DropdownItem tag={Link} to="/logout" className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Cerrar Sesión
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
