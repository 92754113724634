import React from "react";
//import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { API_URL } from "../_config";
import { Redirect } from "react-router-dom";
import { userActions } from "../_actions";
// Aca hacemos importaciones externas
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Form,
  FormInput,
  FormGroup,
  FormTextarea,
  Card,
  CardBody
} from "shards-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch,
  faChalkboardTeacher,
  faBars,
  faHandshake,
  faChessRook,
  faPhone,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Collapse
} from "shards-react";

import axios from "axios";
import Cookies from "universal-cookie";
//import '../../enviroment.js';
// Aca hacemos importaciones de la vista propia
import "./HomeView.css";

var email_data = 0;
var pass_data = 0;

function setEmail(event) {
  email_data = event.target.value;
}

function setPass(event) {
  pass_data = event.target.value;
}

class Home extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    //this.props.logout();
    //
    this.state = {
      username: "",
      password: "",
      submitted: false,
      classn: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    console.log("daond");
    this.setState({ classn: this.state.classn ? "" : "open" });
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    if (username && password) {
      this.props.login(username, password);
      return <Redirect to="/target" />;
    }
  }

  render() {
    const { loggingIn } = this.props;
    const { username, password, submitted } = this.state;

    return (
      <div>
        <Navbar
          type="dark"
          theme="primary"
          expand="md"
          className="fixed_navbar"
        >
          <NavbarBrand href="#" className="centrar-verticalmente">
            <h5 className="naranja sin-bottom">NuestrosAutos</h5>
          </NavbarBrand>

          <div className="ml-auto">
            {this.login_space}

            <div
              id="nav-icon1"
              className={`cd ${this.state.classn}`}
              onClick={this.handleClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={`desktop-only collapsible ${this.state.classn}`}>
            <form
              className="form-inline loginForm"
              role="form"
              onSubmit={this.handleSubmit}
            >
              <div className="form-group">
                <div className="col-xs-6 col-sm-12 col-md-12">
                  <label className="sr-only">E-mail </label>
                  <input
                    type="text"
                    placeholder="E-mail"
                    className="form-control"
                    name="username"
                    value={username}
                    onChange={this.handleChange}
                  />

                  {submitted && !username && (
                    <div className="help-block">Username is required</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="col-xs-6 col-sm-12 col-md-12">
                  <label className="sr-only">Clave</label>
                  <input
                    type="password"
                    placeholder="Contraseña"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                  />
                  {submitted && !password && (
                    <div className="help-block">Password is required</div>
                  )}
                  <span
                    toggle="#password"
                    style={{ color: "white", marginLeft: "10px" }}
                    className="fa fa-fw fa-eye field-icon toggle-password"
                  ></span>
                </div>
              </div>
              <div className="form-group">
                <button
                  className="btn btn-default"
                  style={{ background: "white" }}
                >
                  Acceder
                </button>
              </div>
              {loggingIn && (
                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
              )}
            </form>
          </div>
        </Navbar>

        <div
          style={{
            width: "100%",
            left: "0",
            top: "86px",
            paddingTop: "15px",
            paddingBottom: "15px",
            background: "white"
          }}
          className={`mobile-only collapsible ${this.state.classn}`}
        >
          <form
            className="form-inline loginForm"
            role="form"
            style={{ width: "100%" }}
            onSubmit={this.handleSubmit}
          >
            <div className="form-group" style={{ width: "100%" }}>
              <div className="col-xs-6 col-sm-12 col-md-12">
                <label className="sr-only">E-mail </label>
                <input
                  placeholder="E-mail"
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={this.handleChange}
                />

                {submitted && !username && (
                  <div className="help-block">Username is required</div>
                )}
              </div>
            </div>
            <div className="form-group" style={{ width: "100%" }}>
              <div className="col-xs-6 col-sm-12 col-md-12">
                <label className="sr-only">Clave</label>
                <input
                  placeholder="Contraseña"
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                />
                {submitted && !password && (
                  <div className="help-block">Password is required</div>
                )}
                <span
                  toggle="#password"
                  className="fa fa-fw fa-eye field-icon toggle-password"
                ></span>
              </div>
            </div>

            <div className="form-group" style={{ width: "100%" }}>
              <div className="col-xs-6 col-sm-12 col-md-12">
                <button style={{ width: "100%" }} className="btn btn-default">
                  Acceder
                </button>
              </div>
            </div>
            {loggingIn && (
              <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            )}
          </form>
        </div>

        <div className="tops">
          <Container fluid className="container-sin-derecha">
            <Row>
              <Col sm="6" className="side">
                <Container>
                  <Container fluid>
                    <img
                      src="/img/logo.png"
                      className="logo-cca-img"
                      style={{ maxWidth: "200px" }}
                    />

                    <h1 className="letra" style={{ textAlign: "left" }}>
                      NuestrosAutos
                    </h1>
                    <p className="texto" style={{ margin: "0px" }}>
                      {" "}
                      La aplicación de la Cámara del Comercio Automotor para sus
                      socios. Hoy podes administrar tu agencia y desarrollarla
                      con nuevas tecnologías. Totalmente segura y gratuita.
                    </p>

                    <Row style={{ marginTop: "10px" }}>
                      <Col sm={6}>
                        <Button size="lg" className="margin-doble">
                          REGISTRARSE
                        </Button>
                      </Col>
                      <Col sm={6}>
                        <Button
                          onClick={this.handleClick}
                          size="lg"
                          theme="success"
                          className="border-verde margin-doble"
                        >
                          INGRESAR
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </Col>
              <Col sm="6">
                <img src="/img/pantalla1.png" className="pantalla1" />
              </Col>
            </Row>
          </Container>
        </div>

        <Container className="centerized container-con-padding-top">
          <h3>SERVICIOS</h3>
          <br />
          <Card>
            <CardBody>
              <Row>
                <Col sm="4" className="centerized">
                  <div className="icon-body icon-color-rose">
                    <FontAwesomeIcon icon={faCodeBranch} className="iconos" />
                  </div>
                  <h5>LLEGÁ A TUS CLIENTES</h5>
                  <p>
                    Manejá tu stock y multipublicá tus vehículos en los
                    principales sitios de ventas y redes sociales.
                  </p>
                </Col>
                <Col sm="4" className="centerized">
                  <div className="icon-body icon-color-green">
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      className="iconos"
                    />
                  </div>
                  <h5>NUEVAS TECNOLOGÍAS</h5>
                  <p>
                    Todos los usuarios podrán contar con un sitio web
                    actualizado, dinámico y que posiciona muy bien en las
                    búsquedas de Google.
                  </p>
                </Col>
                <Col sm="4" className="centerized">
                  <div className="icon-body icon-color-blue">
                    <FontAwesomeIcon icon={faHandshake} className="iconos" />
                  </div>
                  <h5>MÁS NEGOCIOS</h5>
                  <p>
                    Cerrá acuerdos con bancos y compañías de seguros confiables.
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <br />
          <br />
          <br />
          <h3>TODOS LOS PORTALES EN UNO</h3>
          <p style={{ textAlign: "center" }}>
            Publicá tus vehículos desde un sólo lugar en los sitios de internet
            más visitados por tus potenciales clientes.
          </p>
          <br />
          <img
            src={API_URL + "api/assets/img/svg/social_10.svg"}
            className="social_img"
          ></img>

          <br />
          <br />
          <br />
        </Container>

        <div className="gray_bar">
          <Row>
            <Col sm="4" className="centerized naranja ">
              NuestrosAutos
            </Col>
            <Col sm="6" className="centerized pk">
              <p>
                Manejá el stock de tus vehículos y multipublicalos desde tu
                computadora o desde tu teléfono celular.
              </p>
            </Col>
            <Col sm="2" className="centerized">
              <img src="/img/logo_1.png" className="social_img"></img>
            </Col>
          </Row>
        </div>

        <div className="footer">
          <br />
          <br />
          <Container>
            <h2 className="centerized texto_blanco">CONTACTENOS</h2>
            <br />
            <br />
            <Row>
              <Col sm="4" className="centerized texto_blanco">
                <div className="icon-body-white">
                  <FontAwesomeIcon icon={faChessRook} className="iconos-con" />
                </div>
                <h6 className="texto_blanco">
                  Soler 3909 Palermo, C1425BWO CABA
                </h6>
              </Col>
              <Col sm="4" className="centerized texto_blanco">
                <div className="icon-body-white">
                  <FontAwesomeIcon icon={faPhone} className="iconos-con" />
                </div>
                <h6 className="texto_blanco">011 4824-7272</h6>
              </Col>
              <Col sm="4" className="centerized texto_blanco">
                <div className="icon-body-white">
                  <FontAwesomeIcon icon={faEnvelope} className="iconos-con" />
                </div>
                <h6 className="texto_blanco">info@nuestrosautos.org</h6>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col sm="7" className="texto_blanco">
                <h3 className="texto_blanco">CONTACTENOS</h3>
                <br />
                <p>
                  Pedí asesoramiento ahora mismo y comenzá a capacitarte para
                  mejorar tu negocio utilizando nuevas tecnologías de una manera
                  fácil, segura y gratuita.
                </p>
              </Col>
              <Col sm="5">
                <Form>
                  <FormGroup>
                    <FormInput
                      size="lg"
                      type="text"
                      name="concesionaria"
                      placeholder="Su Concesionaria"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormInput
                      size="lg"
                      type="email"
                      name="email"
                      placeholder="Su E-mail"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormInput
                      size="lg"
                      type="number"
                      name="phone"
                      placeholder="Teléfono"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormTextarea
                      size="lg"
                      rows="4"
                      name="mensaje"
                      placeholder="Mensaje"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button size="lg">ENVIAR MENSAJE</Button>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedLoginPage = connect(
  mapState,
  actionCreators
)(Home);
export { connectedLoginPage as Home };
