import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, isDirty, getFormValues } from "redux-form";
import InputField from "../_common/InputField";
import SelectSimple from "../_common/SelectSimple";
import { adminuserActions } from "../_actions";
import { store } from "../_helpers";
import { RedNuestrosAutos } from "../RedNuestrosAutos";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import axios from "axios";
import { API_URL } from "../_config";
import {perm_validate} from "../_helpers/perm_validate"
import {
  Row,
  Alert,
  Col,
  Card,
  Button,
  CardFooter,
  CardHeader,
  CardBody
} from "shards-react";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const Panel = props => {
  const { handleSubmit, handleChange, pristine, reset, submitting } = props;

  const [loading, setLoading] = useState(false);
  const [dlz, setDlz] = useState([{}]);

  const [alerti, setAlerti] = useState("");
  const [alertavista, setalertavista] = useState(false);

  // Datos de las cards
  const [dato_habitualista, setHabitualista] = useState([]);
  const [dato_noticias, setNoticias] = useState([]);
  const [dato_novedades, setNovedades] = useState([]);

  const close_alert = () => {
    cookies.set("alerta_" + alerti.id, true);

    setalertavista(false);
    setAlerti("");
  };

  

  useEffect(() => {

    console.log(perm_validate('Concesionarias', 'list'));
    console.log('eaa');
    axios
      .get(API_URL + "getlastalert")
      .then(response => {
        var id_alert = response.data.id;

        
          setAlerti(response.data);

          if(!cookies.get('alerta_' + id_alert) && response.data.active == 1) {
            setalertavista(true);
          }
          
      });

    // Se obtienen las marcas para guardarlas en una cookie
    localStorage.removeItem("vehicle_list");
    axios({
      method: "get",
      url:
        API_URL + "api/vehicle/brands?token=" +
        props.user.token
    }).then(response => {
      // Procedo a guardarlo en una cookie
      localStorage.setItem("brands_list", JSON.stringify(response.data));
    });

    // Se obtienen los modelos para guardarlos en una cookie
    axios({
      method: "get",
      url:
        API_URL + "api/v2/vehicle/models?token=" +
        props.user.token
    }).then(response => {
      // Procedo a guardarlo en una cookie
      localStorage.setItem("models_list", JSON.stringify(response.data));
    });

    // Se obtienen los colores para guardarlos en una cookie
    axios({
      method: "get",
      url:
        API_URL + "api/vehicle/colors?token=" +
        props.user.token
    }).then(response => {
      // Procedo a guardarlo en una cookie
      localStorage.setItem("colors_list", JSON.stringify(response.data));
    });


    // Obtengo los datos de Habitualista
    axios({
      method: "get",
      url:
        "https://cca.org.ar/wp-json/wp/v2/posts?categories=915"
    }).then(response => {
      if(isBrowser) {
        setHabitualista({
          titulo: response.data[0].title.rendered,
          descripcion: '<p style="text-align:left">Para leer la disposición completa haga click a continuación: ' + '<a target="_blank" href=' + response.data[0].link + '>Leer más</a></p>'
        })
       }else{
         setHabitualista({
           titulo: response.data[0].title.rendered,
           descripcion: '<p style="text-align:left">Para leer la disposición completa haga click a continuación: ' + '<a href=' + response.data[0].link + '>Leer más</a></p>'
         })
       }

    });

    // Obtengo los datos de Noticias
    axios({
      method: "get",
      url:
        "https://cca.org.ar/wp-json/wp/v2/posts?categories=275"
    }).then(response => {
    if(isBrowser) {
     setNoticias({
       titulo: response.data[0].title.rendered,
       descripcion: '<p style="text-align:left">' + response.data[0].excerpt.rendered.substr(3,140) + '... <a target="_blank" href=' + response.data[0].link + '>Leer más</a></p>'
     })
    }else{
      setNoticias({
        titulo: response.data[0].title.rendered,
        descripcion: '<p style="text-align:left">' + response.data[0].excerpt.rendered.substr(3,140) + '... <a href=' + response.data[0].link + '>Leer más</a></p>'
      })
    }
    });

    // Obtengo los datos de Novedades
    axios({
      method: "get",
      url:
        "https://cca.org.ar/wp-json/wp/v2/posts?categories=1608"
    }).then(response => {
    if(isBrowser) {
      setNovedades({
        titulo: response.data[0].title.rendered,
        descripcion: '<p style="text-align:left">' + response.data[0].excerpt.rendered.substr(3,140) + '... <a target="_blank" href=' + response.data[0].link + '>Leer más</a></p>'
      })
    }else{
      setNovedades({
        titulo: response.data[0].title.rendered,
        descripcion: '<p style="text-align:left">' + response.data[0].excerpt.rendered.substr(3,140) + '... <a href=' + response.data[0].link + '>Leer más</a></p>'
      })
    }
    });
    
  }, []);

  if (loading == true) {
    return (
      <div
        style={{
          height: "500px",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex"
        }}
      >
        <img src="/loading.gif" style={{ maxWidth: "200px", width: "100%" }} />
      </div>
    );
  }

  const renderHTML = (rawHTML: string) => React.createElement("span", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <div>
      <Alert
        theme="danger"
        dismissible={close_alert}
        open={alertavista}
        style={{ marginLeft: "-24px", marginRight: "-24px" }}
      >
        <div
          style={{ paddingLeft: "24px", paddingRight: "24px" }}
          dangerouslySetInnerHTML={{ __html: alerti.text }}
        ></div>
      </Alert>

      <Row className="mt-4">
        <Col sm="3">
          <Card
            style={{
              borderRadius: ".625rem",
              marginTop: "5px",
              marginBottom: "5px"
            }}
          >
            <a href="./formularios">
              <img
                src="./img/FORMULARIOS.jpg"
                style={{ borderRadius: ".625rem", maxWidth: "100%" }}
              />
            </a>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            style={{
              borderRadius: ".625rem",
              marginTop: "5px",
              marginBottom: "5px"
            }}
          >
            <a href="/seguros">
              <img
                src="./img/SEGUROS.jpg"
                style={{ borderRadius: ".625rem", maxWidth: "100%" }}
              />
            </a>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            style={{
              borderRadius: ".625rem",
              marginTop: "5px",
              marginBottom: "5px"
            }}
          >
            <a href="https://cca.org.ar/lista-de-precios/" target="_blank">
              <img
                src="./img/LISTA-DE-PRECIOS-CAMARA-VAL.jpg"
                style={{ borderRadius: ".625rem", maxWidth: "100%" }}
              />
            </a>
          </Card>
        </Col>
        <Col sm="3">
          <Card
            style={{
              borderRadius: ".625rem",
              marginTop: "5px",
              marginBottom: "5px"
            }}
          >
            <a href="https://cca.org.ar/servicios/" target="_blank">
              <img
                src="./img/SERVICIOS-AL-SOCIO.jpg"
                style={{ borderRadius: ".625rem", maxWidth: "100%" }}
              />
            </a>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4 d-flex align-items-stretch">
        <Col sm="4" className="d-flex align-items-stretch">
          <Card small className="card-post mb-4">
            <CardHeader>
              <h4 className="card-title">Novedades</h4>
            </CardHeader>
            <CardBody className="border-top">
              <p className="t-2">{renderHTML(dato_novedades.titulo)}</p>
              <p>{renderHTML(dato_novedades.descripcion)}</p>
            </CardBody>
          </Card>
        </Col>
        <Col sm="4" className="d-flex align-items-stretch">
          <Card small className="card-post mb-4">
            <CardHeader>
              <h4 className="card-title">Noticias CCA</h4>
            </CardHeader>
            <CardBody className="border-top">
              <p className="t-2">{renderHTML(dato_noticias.titulo)}</p>
              <p>{renderHTML(dato_noticias.descripcion)}</p>
            </CardBody>
          </Card>
        </Col>
        <Col sm="4" className="d-flex align-items-stretch">
          <Card small className="card-post mb-4">
            <CardHeader>
              <h4 className="card-title">Habitualista</h4>
            </CardHeader>
            <CardBody className="border-top">
              <p className="t-2">{renderHTML(dato_habitualista.titulo)}</p>
              <p>{renderHTML(dato_habitualista.descripcion)}</p>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <RedNuestrosAutos></RedNuestrosAutos>
    </div>
  );
};

function mapState(state, props) {
  const { users, authentication, vehicles, adminuser } = state;

  const { user } = authentication;

  var initl = {};

  return { user, users, adminuser, initialValues: initl };
}
const actionCreators = {};

const po = reduxForm({
  form: "Panel",
  enableReinitialize: true
})(Panel);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as Panel };
