import * as firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/firestore';

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyC1_0YPdfyAtzEPyqEqnnuXlTPYDz28vuY',
  authDomain: 'nuestrosautos.firebaseapp.com',
  databaseURL: 'https://nuestrosautos.firebaseio.com',
  projectId: 'nuestrosautos',
  storageBucket: 'nuestrosautos.appspot.com',
  messagingSenderId: '335738538887',
  appId: '1:335738538887:web:006151dd56fbf8153c2122',
});

const messaging = firebase.messaging.isSupported() ? initializedFirebaseApp.messaging() : null;
if (messaging) {
  messaging.getToken({ vapidKey: 'BGrLIn05EmCz6o2Ba1ZqGUm8giZCzFV5M8XYlTFUPA0ASF4-61YBFkNg5e2nc2ljREV6S8WgO5LbMNYqzwk9PJQ' });
}

const database = initializedFirebaseApp.firestore();

export { messaging, database };
