import React from "react";
import PropTypes from "prop-types";

const PortalsIcon = ({ api, apiIcon, url, content, RealName, type, ...attrs }) => { 

    if(url && url.length > 1) {

        if(api == 'olx') {
            apiIcon = "/img/olx.png";
            RealName = 'OLX';
    
        }else if(api == 'mercadolibre') {
            apiIcon = "/img/meli.png";
            RealName = 'MercadoLibre';
    
        }else if(api == 'facebook') {
            apiIcon = "/img/facebook.png";
            RealName = 'Facebook';
            
        }else if(api == 'instagram') {
            apiIcon = "/img/instagram.png";
            url = 'https://instagram.com/p/' + url;
            RealName = 'Instagram';
            
        }else if(api == 'demotores') {
            apiIcon = "/img/dm.png";
            RealName = 'DeMotores';
    
        }else if(api == 'nuestrosautos') {
            apiIcon = "/img/na.png";
            RealName = 'NuestrosAutos';
    
        }else if(api == 'Deautos') {
            apiIcon = "/img/deautos.png";
            RealName = 'Deautos';
    
        }else if(api == 'autocosmos') {
            apiIcon = "/img/autocosmos.png";
            RealName = 'AutoCosmos';
        }else if(api == 'lavoz') {
            apiIcon = "/img/lavoz.png";
            RealName = 'LaVoz';
        }else if(api == 'www') {
            apiIcon = "/img/wwww.png";
            RealName = 'WWW';
        }

        if(type == 'option'){
            return (
                <option value={url}>{RealName}</option>
            )
        }else{
            return (
            <a href={url} target="_blank"> <img
            style={{
                borderRadius: "5px",
                width: "1.8rem",
                height: "1.8rem",
                margin: "0 8px 4px 0",
                boxShadow: "-1px 4px 8px -11px rgba(0,0,0,0.75)"
            }}
            src={apiIcon}
                /></a>
                )
        }
    }else {
      return '';
    }


};


PortalsIcon.propTypes = {
  api: PropTypes.string,
  url: PropTypes.string
};


export default PortalsIcon;
