import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  FormInput,
  Modal,
  Tooltip,
  ModalBody,
  ModalHeader
} from "shards-react";
import { Radio, Checkbox } from 'antd';
import CardFlip from "../_components/CardFlip";
import PageTitle from "../_components/utils/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkInstagram from "./LinkInstagram";
import LinkDM from "./LinkDM";
import { faUndo, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../LinkingPage/LinkingPage.css";
import { store } from "../_helpers";
import axios from "axios";
import Cookies from "universal-cookie";
import LinkAC from "./LinkAC";
import LinkOLX from "./LinkOLX";
import LinkDeAutos from "./LinkDeAutos";
import ModalConfirmDesv from "./ModalConfirmDesv";
import PolyLoader from "../_components/PolyLoader";
import { FacebookProvider, LoginButton, Status } from "react-facebook";
import FacebookLibrary from "../_common/FacebookLibrary";
import { API_URL, PREAPI_URL } from "../_config";

const cookies = new Cookies();

class LinkingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isFlipped_mercadolibre: false,
      isFlipped_demotores: false,
      isFlipped_olx: false,
      isFlipped_autocosmos: false,
      isFlipped_instagram: false,
      isFlipped_facebook: false,
      isFlipped_lavoz: false,
      isFlipped_deautos: false,
      open: false,
      openModaDev: false,
      selectDesvinAccountState: "",

      // Ahora los estados de vinculacion:
      instagram: false,
      mercadolibre: false,
      demotores: false,
      facebook: false,
      AcepTermCondi: true,
      olx: false,
      lavoz: false,
      deautos: false,
      autocosmos: false,
      autocosmos_select: "nothing",
      autocosmos_mail: "",
      autocosmosAutoPublic: true,
      cargar: true,
      cargar2: true,
      cargar3: false,
      mercadolibre_mail: "",
      demotores_mail: "",
      olx_mail: "",
      count_free: 0,
      count_gold: 0,
      count_gold_premium: 0,
      count_silver: 0
    };
    this.handleClick_mercadolibre = this.handleClick_mercadolibre.bind(this);
    this.handleClick_demotores = this.handleClick_demotores.bind(this);
    this.handleClick_olx = this.handleClick_olx.bind(this);
    this.handleClick_lavoz = this.handleClick_lavoz.bind(this);
    this.handleClick_deautos = this.handleClick_deautos.bind(this);
    this.handleClick_autocosmos = this.handleClick_autocosmos.bind(this);
    this.handleClick_autocosmos_public_automatic = this.handleClick_autocosmos_public_automatic.bind(this);
    this.handleClick_instagram = this.handleClick_instagram.bind(this);
    this.handleClick_facebook = this.handleClick_facebook.bind(this);
    this.link_instagram = this.link_instagram.bind(this);
    this.unlink_instagram = this.unlink_instagram.bind(this);
    this.link_demotores = this.link_demotores.bind(this);
    this.unlink_demotores = this.unlink_demotores.bind(this);
    this.link_autocosmos = this.link_autocosmos.bind(this);
    this.unlink_autocosmos = this.unlink_autocosmos.bind(this);
    this.register_ac = this.register_ac.bind(this);
    this.login_ac = this.login_ac.bind(this);
    this.link_olx = this.link_olx.bind(this);
    this.unlink_olx = this.unlink_olx.bind(this);
    this.link_lavoz = this.link_lavoz.bind(this);
    this.unlink_lavoz = this.unlink_lavoz.bind(this);
    this.link_deautos = this.link_deautos.bind(this);
    this.unlink_deautos = this.unlink_deautos.bind(this);
    this.link_mercadolibre = this.link_mercadolibre.bind(this);
    this.unlink_mercadolibre = this.unlink_mercadolibre.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.loginFB = this.loginFB.bind(this);
    this.unlink_fb = this.unlink_fb.bind(this);
    this.toggle = this.toggle.bind(this);
    this.modalConfirmDesvFn = this.modalConfirmDesvFn.bind(this);
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  componentWillMount() {
    window.FB.init({
      appId: "857034091332515",
      autoLogAppEvents: true,
      xfbml: true,
      status: true,
      version: "v09.0",
      cookie: true
    });

    window.FB.getLoginStatus(
      function (response) {
        if (response.status === "connected") {
          this.setState({
            facebook: true
          });
          //var uid = response.authResponse.userID;
          //var accessToken = response.authResponse.accessToken;
        } else if (response.status === "not_authorized") {
          alert(
            "No tenemos autorizacion para continuar con Facebook, por favor, contactenos."
          );
        }
      }.bind(this)
    );

    axios
      .get(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking?token=" +
        store.getState().authentication.user.token
      )
      .then(response => {
        if (response.data.mercadolibre.username == true) {
          this.setState({
            mercadolibre: true,
            mercadolibre_mail: response.data.mercadolibre_email,
            count_free: response.data.count_free,
            count_gold: response.data.count_gold,
            count_gold_premium: response.data.count_gold_premium,
            count_silver: response.data.count_silver
          });
        }

        if (response.data.demotores.username == true) {
          this.setState({
            demotores: true,
            demotores_mail: response.data.demotores.data.email
          });
        }

        if (response.data.olx.username == true) {
          this.setState({
            olx: true,
            olx_mail: response.data.olx.data.email
          });
        }
        if (response.data.lavoz.username == true) {
          this.setState({
            lavoz: true,
            lavoz_mail: response.data.lavoz.data.email
          });
        }

        if (response.data.deautos.username == true) {
          this.setState({
            deautos: true
          });
        }

        if (response.data.autocosmos.username == true) {
          this.setState({
            autocosmos: true,
            autocosmosAutoPublic: true,
            autocosmos_mail: response.data.autocosmos.data.email
          });
        }

        this.setState({
          cargar: false
        });
      });

    axios
      .get(
        PREAPI_URL +
        "instagram/state/" +
        cookies.get("dealer").id +
        "/" +
        store.getState().authentication.user.id +
        "?token=" +
        store.getState().authentication.user.token
      )
      .then(response => {
        if (response.data.state != "unlink_po_8967") {
          this.setState({
            instagram: true
          });
        }

        this.setState({
          cargar2: false
        });
      });
  }
  modalConfirmDesvFn(account) {
    this.setState({
      openModaDev: !this.state.openModaDev,
      selectDesvinAccountState: account
    });
  }
  handleClick_mercadolibre(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isFlipped_mercadolibre: !prevState.isFlipped_mercadolibre
    }));
  }

  handleClick_demotores(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isFlipped_demotores: !prevState.isFlipped_demotores
    }));
  }

  handleClick_olx(e) {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped_olx: !prevState.isFlipped_olx }));
  }

  handleClick_lavoz(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isFlipped_lavoz: !prevState.isFlipped_lavoz
    }));
  }

  handleClick_deautos(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isFlipped_deautos: !prevState.isFlipped_deautos
    }));
  }

  handleClick_autocosmos(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isFlipped_autocosmos: !prevState.isFlipped_autocosmos,
      autocosmos_select: "nothing"
    }));
  }
  handleClick_autocosmos_public_automatic(e) {
    console.log(e.target.value, 'DESACTIVE')
  }

  register_ac(e) {
    e.preventDefault();
    this.setState({
      autocosmos_select: "register"
    });
  }

  login_ac(e) {
    e.preventDefault();
    this.setState({
      autocosmos_select: "login"
    });
  }

  handleClick_instagram(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isFlipped_instagram: !prevState.isFlipped_instagram
    }));
  }

  handleClick_facebook(e) {
    e.preventDefault();
    this.setState(prevState => ({
      isFlipped_facebook: !prevState.isFlipped_facebook
    }));
  }

  link_instagram(values) {
    axios
      .post(
        PREAPI_URL +
        "instagram/link?" +
        store.getState().authentication.user.token,
        {
          user_id: store.getState().authentication.user.user.id,
          username: values.user,
          password: values.password,
          car_dealer_id: cookies.get("dealer").id
        }
      )
      .then(response => {
        this.setState({
          instagram: true
        });

        this.setState(prevState => ({
          isFlipped_instagram: !prevState.isFlipped_instagram
        }));
      });
  }

  unlink_instagram() {
    axios
      .get(
        PREAPI_URL +
        "instagram/unlink/" +
        cookies.get("dealer").id +
        "/" +
        store.getState().authentication.user.user.id +
        "?" +
        store.getState().authentication.user.token
      )
      .then(response => {
        console.log(response);
        this.setState({
          instagram: false
        });

        this.setState(prevState => ({
          isFlipped_instagram: !prevState.isFlipped_instagram
        }));
      });
  }

  link_demotores(values) {
    axios
      .post(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          data: {
            portal: "demotores",
            data: JSON.stringify({
              email: values.email,
              user_id: values.user_id
            })
          }
        }
      )
      .then(response => {
        this.setState({
          demotores: true
        });

        this.setState({
          isFlipped_demotores: false
        });
      });
  }

  unlink_demotores() {
    axios
      .delete(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking/demotores?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          portal: "demotores"
        }
      )
      .then(response => {
        this.setState({
          demotores: false
        });

        this.setState(prevState => ({
          isFlipped_demotores: !prevState.isFlipped_demotores
        }));
      });
  }

  link_autocosmos(values) {
    if (this.state.autocosmos_select == "register") {
      var nuevo = true;
      var city = values.city;
    } else {
      var nuevo = false;
      var city = "no es requerido";
    }

    axios
      .post(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          data: {
            portal: "autocosmos",
            data: JSON.stringify(values),
            new: nuevo,
            city: city
          }
        }
      )
      .then(response => {
        this.setState({
          autocosmos: true
        });

        this.setState({
          isFlipped_autocosmos: false
        });
      });
  }

  unlink_autocosmos() {
    axios
      .delete(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking/autocosmos?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          portal: "autocosmos"
        }
      )
      .then(response => {
        this.setState({
          autocosmos: false
        });

        this.setState(prevState => ({
          isFlipped_autocosmos: !prevState.isFlipped_autocosmos,
          autocosmos_select: "nothing"
        }));
      });
  }

  link_lavoz(values) {
    axios
      .post(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          data: {
            portal: "lavoz",
            data: JSON.stringify({
              email: values.email,
              user: values.user
            })
          }
        }
      )
      .then(response => {
        this.setState({
          lavoz: true
        });

        this.setState({
          isFlipped_lavoz: false
        });
      });
  }

  link_deautos(values) {

    axios
      .post(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          data: {
            portal: "deautos",
            data: JSON.stringify({
              email: values.email,
              password: values.password
            })
          }
        }
      )
      .then(response => {
        this.setState({
          deautos: true
        });

        this.setState({
          isFlipped_deautos: false
        });
      });
  }

  unlink_deautos() {
    axios
      .delete(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking/deautos?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          portal: "deautos"
        }
      )
      .then(response => {
        this.setState({
          deautos: false
        });

        this.setState(prevState => ({
          isFlipped_deautos: !prevState.isFlipped_deautos
        }));
      });
  }

  unlink_lavoz() {
    axios
      .delete(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking/lavoz?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          portal: "lavoz"
        }
      )
      .then(response => {
        this.setState({
          lavoz: false
        });

        this.setState(prevState => ({
          isFlipped_lavoz: !prevState.isFlipped_lavoz
        }));
      });
  }

  link_olx(values) {
    axios
      .post(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          data: {
            portal: "olx",
            data: JSON.stringify({
              email: values.email,
              user: values.user
            })
          }
        }
      )
      .then(response => {
        this.setState({
          olx: true
        });

        this.setState({
          isFlipped_olx: false
        });
      });
  }

  unlink_olx() {
    axios
      .delete(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking/olx?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          portal: "olx"
        }
      )
      .then(response => {
        this.setState({
          olx: false
        });

        this.setState(prevState => ({
          isFlipped_olx: !prevState.isFlipped_olx
        }));
      });
  }

  link_mercadolibre() {
    var state = "," + cookies.get("dealer").id;
    var url =
      PREAPI_URL +
      "api/mercadolibre/app-login?token=" +
      store.getState().authentication.user.token +
      "&state=" +
      state;
    window.open(
      url,
      "Mercadolibre-Login",
      "width=700,height=450,scrollbars=NO"
    );
  }

  unlink_mercadolibre() {
    axios
      .delete(
        PREAPI_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/linking/mercadolibre?token=" +
        store.getState().authentication.user.token,
        {
          id: cookies.get("dealer").id,
          portal: "mercadolibre"
        }
      )
      .then(response => {
        this.setState({
          mercadolibre: false
        });
      });
  }

  handleResponse(data) {
    axios
      .get(
        PREAPI_URL +
        "api/facebook/login_accounts/" +
        data.tokenDetail.accessToken +
        "?token=" +
        store.getState().authentication.user.token
      )
      .then(response => {
        this.setState({
          facebook: true
        });
      });
  }

  loginFB() {

    window.FB.login(
      function (response) {
        if (response.authResponse) {
          axios
            .get(
              PREAPI_URL +
              "api/facebook/login_accounts/" +
              response.authResponse.accessToken +
              "?token=" +
              store.getState().authentication.user.token
            )
            .then(response => {
              this.setState({
                facebook: true
              });
            });
        } else {
          alert("No hemos podido loguear con Facebook");
        }
      }.bind(this),
      { //pages_show_list,public_profile,pages_read_engagement,pages_manage_posts,email,instagram_basic,instagram_content_publish,pages_read_engagement
        scope: "email,pages_show_list,instagram_basic,instagram_content_publish,pages_manage_posts,public_profile",
        return_scopes: true, //scope: 'manage_pages,publish_pages,publish_actions,public_profile', //// business_management
        auth_type: "rerequest"
      }
    );
  }

  unlink_fb() {
    window.FB.logout();

    this.setState({ facebook: false });
  }

  handleError(error) {
    alert(
      "Ha ocurrido un error en la vinculación, por favor, intente más tarde"
    );
    console.log(error);
  }
  render() {
    const fnsList = {
      ac: this.unlink_autocosmos,
      da: this.unlink_deautos,
      dm: this.unlink_demotores,
      fb: this.unlink_fb,
      inst: this.unlink_instagram,
      lv: this.unlink_lavoz,
      ml: this.unlink_mercadolibre,
      olx: this.unlink_olx,
    }
    return (
      <>
        <Modal
          size="lg"
          open={this.state.isFlipped_facebook}
          toggle={() => console.log("Toggle")}
        >
          <ModalHeader>
            Vincular con Facebook{" "}
            <i
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                fontSize: "25px",
                cursor: "pointer"
              }}
              class="material-icons"
              onClick={this.toggle}
            >
              clear
            </i>
          </ModalHeader>
        </Modal>

        <Modal
          size="md"
          open={this.state.cargar3}
          toggle={() => console.log("Toggle")}
        >
          <ModalHeader>
            Ooops{" "}
            <i
              style={{
                position: "absolute",
                right: "20px",
                top: "15px",
                fontSize: "25px",
                cursor: "pointer"
              }}
              class="material-icons"
              onClick={this.toggle}
            >
              clear
            </i>
          </ModalHeader>
          <ModalBody>
            Ha ocurrido un problema al intentar cargar Facebook, te recomendamos
            refrescar la página pulsando el siguiente botón:
            <Button
              style={{ marginTop: "10px", maxWidth: "200px" }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Refrescar
            </Button>
          </ModalBody>
        </Modal>

        <PolyLoader
          open={this.state.cargar && this.state.cargar2}
          text="Cargando"
        ></PolyLoader>
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Vinculaciones" className="text-sm-left" />
        </Row>

        <Row>
          <Col lg="4" md="6" sm="6" style={{ "margin-bottom": "20px" }}>
            <CardFlip
              isFlipped={this.state.isFlipped_mercadolibre}
              flipDirection="horizontal"
            >
              <Card key="front">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <img
                    src="/img/meli_wide.png"
                    style={{
                      width: "100%",
                      borderRadius: "0.625rem 0.625rem 0px 0px"
                    }}
                  ></img>
                  <Container>
                    <br />
                    <h4 style={{ fontSize: "22px" }}>MercadoLibre</h4>

                    {this.state.mercadolibre ? (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Vinculado con{" "}
                          {this.state.mercadolibre_mail.replace("\n", " ")}.
                          Publicaciones Disponibles: Gratis:{" "}
                          <b>{this.state.count_free}</b> - Silver:{" "}
                          <b>{this.state.count_silver}</b> - Oro:{" "}
                          <b>{this.state.count_gold}</b> - Oro Premium:{" "}
                          <b>{this.state.count_gold_premium}</b>
                        </span>

                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={() => {
                            this.modalConfirmDesvFn("ml")
                            // if (window.confirm("¿Seguro?")) {
                            //   this.unlink_mercadolibre();
                            // }
                          }}
                          outline
                        >
                          Desvincular
                        </Button>
                      </>
                    ) : (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Para vincular debe tener una cuenta activa
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={this.link_mercadolibre}
                          outline
                        >
                          Vincular
                        </Button>
                      </>
                    )}
                  </Container>
                </CardBody>
              </Card>

              <Card key="back">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <Container>
                    <br />
                    <h5>Vincular con MercadoLibre</h5>
                    <Form>
                      <FormGroup>
                        <label htmlFor="#username">E-mail</label>
                        <FormInput
                          id="#username"
                          placeholder="hola@email.com"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="#password">Contraseña</label>
                        <FormInput
                          type="password"
                          id="#password"
                          placeholder="Contraseña"
                        />
                      </FormGroup>
                    </Form>

                    <br />
                    <FontAwesomeIcon
                      style={{ fontSize: "40px", float: "right" }}
                      icon={faArrowRight}
                    />

                    <FontAwesomeIcon
                      onClick={this.handleClick_autocosmos}
                      style={{ fontSize: "40px", marginTop: "12px" }}
                      icon={faUndo}
                    />

                    <br />
                  </Container>
                </CardBody>
              </Card>
            </CardFlip>
          </Col>

          <Col lg="4" md="6" sm="6" style={{ "margin-bottom": "20px" }}>
            <CardFlip
              isFlipped={this.state.isFlipped_demotores}
              flipDirection="horizontal"
            >
              <Card key="front">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <img
                    src="/img/dm_wide.png"
                    style={{
                      width: "100%",
                      borderRadius: "0.625rem 0.625rem 0px 0px"
                    }}
                  ></img>
                  <Container>
                    <br />
                    <h4 style={{ fontSize: "22px" }}>Demotores</h4>

                    {this.state.demotores ? (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Vinculado con {this.state.demotores_mail}
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={() => {
                            this.modalConfirmDesvFn("dm")
                            // if (window.confirm("¿Seguro?")) {
                            //   this.unlink_demotores();
                            // }
                          }}
                          outline
                        >
                          Desvincular
                        </Button>
                      </>
                    ) : (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Para vincular debe tener una cuenta activa
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={this.handleClick_demotores}
                          outline
                        >
                          Vincular
                        </Button>
                      </>
                    )}

                    <br></br>
                  </Container>
                </CardBody>
              </Card>

              <Card key="back">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <Container>
                    <br />
                    <h5>Vincular con Demotores</h5>
                    <LinkDM onSubmit={this.link_demotores} />

                    <FontAwesomeIcon
                      onClick={this.handleClick_demotores}
                      style={{ fontSize: "40px", marginTop: "12px" }}
                      icon={faUndo}
                    />

                    <br />
                  </Container>
                </CardBody>
              </Card>
            </CardFlip>
          </Col>

          <Col lg="4" md="6" sm="6" style={{ "margin-bottom": "20px" }}>
            <CardFlip
              isFlipped={this.state.isFlipped_olx}
              flipDirection="horizontal"
            >
              <Card key="front">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <img
                    src="/img/olx_wide.png"
                    style={{
                      width: "100%",
                      borderRadius: "0.625rem 0.625rem 0px 0px"
                    }}
                  ></img>
                  <Container>
                    <br />
                    <h4 style={{ fontSize: "22px" }}>OLX</h4>

                    {this.state.olx ? (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Vinculado con {this.state.olx_mail}
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={() => {
                            this.modalConfirmDesvFn("olx")
                            // if (window.confirm("¿Seguro?")) {
                            //   this.unlink_olx();
                            // }
                          }}
                          outline
                        >
                          Desvincular
                        </Button>
                      </>
                    ) : (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Para vincular debe tener una cuenta activa
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={this.handleClick_olx}
                          outline
                        >
                          Vincular
                        </Button>
                      </>
                    )}
                    <br />
                  </Container>
                </CardBody>
              </Card>

              <Card key="back">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <Container>
                    <br />
                    <h5>Vincular con OLX</h5>
                    <LinkOLX onSubmit={this.link_olx} />

                    <FontAwesomeIcon
                      onClick={this.handleClick_olx}
                      style={{ fontSize: "40px", marginTop: "12px" }}
                      icon={faUndo}
                    />

                    <br />
                  </Container>
                </CardBody>
              </Card>
            </CardFlip>
          </Col>

          <Col lg="4" md="6" sm="6" style={{ "margin-bottom": "20px" }}>
            <CardFlip
              isFlipped={this.state.isFlipped_lavoz}
              flipDirection="horizontal"
            >
              <Card key="front">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <img
                    src="/img/lavoz_wide.png"
                    style={{
                      width: "100%",
                      borderRadius: "0.625rem 0.625rem 0px 0px"
                    }}
                  ></img>
                  <Container>
                    <br />
                    <h4 style={{ fontSize: "22px" }}>La Voz</h4>

                    {this.state.lavoz ? (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Vinculado
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={() => {
                            this.modalConfirmDesvFn("lv")
                            // if (window.confirm("¿Seguro?")) {
                            //   this.unlink_lavoz();
                            // }
                          }}
                          outline
                        >
                          Desvincular
                        </Button>
                      </>
                    ) : (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Para vincular debe tener una cuenta activa
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={this.handleClick_lavoz}
                          outline
                        >
                          Vincular
                        </Button>
                      </>
                    )}
                    <br />
                  </Container>
                </CardBody>
              </Card>

              <Card key="back">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <Container>
                    <br />
                    <h5>Vincular con LaVoz</h5>
                    <LinkOLX onSubmit={this.link_lavoz} />

                    <FontAwesomeIcon
                      onClick={this.handleClick_lavoz}
                      style={{ fontSize: "40px", marginTop: "12px" }}
                      icon={faUndo}
                    />

                    <br />
                  </Container>
                </CardBody>
              </Card>
            </CardFlip>
          </Col>

          <Col lg="4" md="6" sm="6" style={{ "margin-bottom": "20px" }}>
            <CardFlip
              isFlipped={this.state.isFlipped_autocosmos}
              flipDirection="horizontal"
            >
              <Card key="front">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <img
                    src="/img/autocosmos_wide.png"
                    style={{
                      width: "100%",
                      borderRadius: "0.625rem 0.625rem 0px 0px"
                    }}
                  ></img>
                  <Container>
                    <br />
                    <h4 style={{ fontSize: "22px" }}>Autocosmos</h4>

                    {this.state.autocosmos ? (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Vinculado con {this.state.autocosmos_mail}
                        </span>
                        <div className="d-flex py-2 justify-content-cente align-items-center">
                          <div>
                            <span className="d-inline-block carta_vinculacion">
                              Publicaciones Automáticas:
                            </span>
                          </div>
                          <div className="pl-4">
                            <Radio.Group onChange={this.handleClick_autocosmos_public_automatic} defaultValue={this.state.autocosmosAutoPublic} buttonStyle="solid">
                              <Radio.Button value={true}>Si</Radio.Button>
                              <Radio.Button value={false}>No</Radio.Button>
                            </Radio.Group>
                          </div>
                        </div>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={() => {
                            this.modalConfirmDesvFn("ac")
                            // if (window.confirm("¿Seguro")) {
                            //   this.unlink_autocosmos();
                            // }
                          }}
                          outline
                        >
                          Desvincular
                        </Button>
                      </>
                    ) : (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Para vincular debe tener una cuenta activa
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={this.handleClick_autocosmos}
                          outline
                        >
                          Vincular
                        </Button>
                      </>
                    )}
                    <br />
                  </Container>
                </CardBody>
              </Card>

              <Card key="back">
                <CardBody className="p-0 pb-3 overklow">
                  <Container>
                    <br />
                    <h5>Vincular con AutoCosmos</h5>
                    {this.state.autocosmos_select != "nothing" ? (
                      <LinkAC
                        tipo={this.state.autocosmos_select}
                        onSubmit={this.link_autocosmos}
                      />
                    ) : (
                      <div>
                        <p className="p_no_margin">Seleccione su situación:</p>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={this.register_ac}
                          outline
                        >
                          Quiero Registrarme
                        </Button>

                        <Button
                          style={{
                            width: "100%",
                            fontSize: "17px",
                            marginTop: "10px"
                          }}
                          onClick={this.login_ac}

                        >
                          Estoy Registrado
                        </Button>
                      </div>
                    )}

                    <FontAwesomeIcon
                      onClick={this.handleClick_autocosmos}
                      style={{ fontSize: "40px", marginTop: "12px" }}
                      icon={faUndo}
                    />

                    <br />
                  </Container>
                </CardBody>
              </Card>
            </CardFlip>
          </Col>
          {/*
          <Col lg="4" md="6" sm="6">
            <CardFlip
              isFlipped={this.state.isFlipped_instagram}
              flipDirection="horizontal"
            >
              <Card key="front" >
                <CardBody className="p-0 pb-3" style={{ overflow: 'auto !important' }}>
                  <img
                    src="/img/instagram_wide.png"
                    style={{ width: "100%" }}
                  ></img>
                  <Container>
                    <br />
                    <h4 style={{ fontSize: "22px" }}>Instagram</h4>
                    <p
                      style={{
                        "margin-left": "2px",
                        "margin-top": "-5px",
                        width: "262px",
                        fontSize: "12px",
                        "margin-bottom": "15px"
                      }}
                    >
                      Para vincular debe tener una cuenta activa
                    </p>
                    {this.state.instagram ? (

                      <Button

                        style={{ width: "100%", fontSize: "17px" }}
                        onClick={this.unlink_instagram}
                        outline
                      >
                        Desvincular
                      </Button>

                    ) : (
                      <Button

                        style={{ width: "100%", fontSize: "17px" }}
                        onClick={this.handleClick_instagram}
                        outline
                      >
                        Vincular
                      </Button>
                    )}
                  </Container>
                </CardBody>
              </Card>

              <Card key="back" >
                <CardBody className="p-0 pb-3" style={{ overflow: 'auto !important' }}>
                  <Container>
                    <br />

                    <FontAwesomeIcon
                      style={{ fontSize: "40px" }}
                      icon={faUndo}
                    />

                    <br />
                  </Container>
                </CardBody>
              </Card>
            </CardFlip>
          </Col>
                    */}
          <br />


          <Col lg="4" md="6" sm="6" style={{ "margin-bottom": "20px" }}>
            <CardFlip isFlipped={false} flipDirection="horizontal">
              <Card key="front">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <img
                    src="/img/facebook_wide.png"
                    style={{
                      width: "100%",
                      borderRadius: "0.625rem 0.625rem 0px 0px"
                    }}
                  ></img>
                  <Container>
                    <br />
                    <h4 style={{ fontSize: "22px" }}>Facebook</h4>

                    {this.state.facebook == false ? (
                      <>
                        <div className="p-2">
                          <span className="d-inline-block carta_vinculacion">
                            Para vincular debe tener una cuenta activa
                          </span>
                          <Checkbox onChange={(e) => e.target.checked === true ? this.setState({ AcepTermCondi: false }) : this.setState({ AcepTermCondi: true })}  >Acepto los<a href="https://cca.org.ar/terminos-y-condiciones-de-uso/" target="_blank"> Términos y Condiciones </a></Checkbox>
                        </div>
                        <Button
                          style={{ width: "100%", fontSize: "17px", color: "#fff" }}
                          onClick={() => this.loginFB()}
                          outline
                          disabled={this.state.AcepTermCondi}
                        >
                          Vincular
                        </Button>
                      </>
                    ) : (
                      <Button
                        style={{ width: "100%", fontSize: "17px" }}
                        onClick={() => this.modalConfirmDesvFn("fb")}
                        outline
                      >
                        Desvincular
                      </Button>
                    )}
                  </Container>
                </CardBody>
              </Card>

              <Card key="back">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <Container>
                    <br />
                    <h5>Vincular con con Facebook</h5>
                    Lo sentimos, pero no hemos podido abrir la ventana de
                    vinculaciones
                    <FontAwesomeIcon
                      onClick={this.handleClick_facebook}
                      style={{ fontSize: "40px" }}
                      icon={faUndo}
                    />
                    <br />
                  </Container>
                </CardBody>
              </Card>
            </CardFlip>
          </Col>
          {/*
          <Col lg="4" md="6" sm="6" style={{ "margin-bottom": "20px" }}>
            <CardFlip
              isFlipped={this.state.isFlipped_deautos}
              flipDirection="horizontal"
            >
              <Card key="front">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <img
                    src="/img/deautos_wide.png"
                    style={{
                      width: "100%",
                      borderRadius: "0.625rem 0.625rem 0px 0px"
                    }}
                  ></img>
                  <Container>
                    <br />
                    <h4 style={{ fontSize: "22px" }}>DeAutos</h4>

                    {this.state.deautos ? (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Vinculado
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          onClick={() => {
                            this.modalConfirmDesvFn("da")

                            // if (window.confirm("¿Seguro?")) {
                            //   this.unlink_deautos();
                            // }
                          }}
                          outline
                        >
                          Desvincular
                        </Button>
                      </>
                    ) : (
                      <>
                        <span className="d-inline-block carta_vinculacion">
                          Proximamente podras vincular tu cuenta DeAutos!
                        </span>
                        <Button
                          style={{ width: "100%", fontSize: "17px" }}
                          // onClick={this.handleClick_deautos}
                          // outline
                          disabled={true}
                        >
                          Proximamente
                        </Button>
                      </>
                    )}
                    <br />
                  </Container>
                </CardBody>
              </Card>

              <Card key="back">
                <CardBody
                  className="p-0 pb-3"
                  style={{ overflow: "auto !important" }}
                >
                  <Container>
                    <br />
                    <h5>Vincular con DeAutos</h5>
                    <LinkDeAutos onSubmit={this.link_deautos} />

                    <FontAwesomeIcon
                      onClick={this.handleClick_deautos}
                      style={{ fontSize: "40px", marginTop: "12px" }}
                      icon={faUndo}
                    />

                    <br />
                  </Container>
                </CardBody>
              </Card>
            </CardFlip>
          </Col>
                    */}
          <br />
        </Row>
        <ModalConfirmDesv fnModalShow={this.modalConfirmDesvFn} selectDesvinAccount={this.state.selectDesvinAccountState} modalShowHide={this.state.openModaDev} desvinculacionFn={fnsList} />
      </>
    );
  }
}

function mapState(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return { user, users };
}
const actionCreators = {};

const connectedDashboard = connect(
  mapState,
  actionCreators
)(LinkingPage);
export { connectedDashboard as LinkingPage };
