import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, getFormValues, reduxForm } from "redux-form";
import { API_URL} from "../_config";
import { Redirect } from "react-router-dom";
import { userActions } from "../_actions";
import ModalForm from "./ModalForm";
import {
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Collapse
} from "shards-react";

import "../NewHome/shards-extras.min.css";
import { Element, scroller } from "react-scroll";
import axios from "axios";
import { store } from "../_helpers";
import { Icon, notification } from "antd";

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = "Requerido";
  }
  if (!values.email) {
    errors.email = "Requerido";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Email invalido";
  }
  if (!values.phone) {
    errors.phone = "Requerido";
  }
  if (!values.message) {
    errors.message = "Requerido";
  }
  return errors;
};

const NewHome = props => {
  const { pristine, reset, submitting, loggingIn } = props;

  const [loading, setLoading] = useState(false);
  const [dlz, setDlz] = useState([{}]);
  const [visible, setVisible] = useState(false);
  const [formRef, setFormRef] = useState(null);

  // Menu
  const [collapseOpen, setCollapseOpen] = useState(false);

  const handleCreate = () => {
    formRef.validateFields((err, values) => {
      if (err) {
        return;
      }

      if (values.usuario && values.pass) {
        props.login(values.usuario, values.pass);
        return <Redirect to="/target" />;
      } else {
        return;
      }

      console.log("Received values of form: ", values);
      formRef.resetFields();
      setVisible(false);
    });
  };

  const renderField = ({
    input,
    label,
    placeholder,
    className,
    type,
    meta: { touched, error, warning }
  }) => (
    <div>
      <input
        {...input}
        className={className}
        placeholder={placeholder}
        type={type}
      />
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );

  const renderTextarea = ({
    input,
    label,
    className,
    type,
    meta: { touched, error, warning }
  }) => (
    <div>
      <textarea
        {...input}
        className={className}
        placeholder={label}
        type={type}
      ></textarea>
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  );

  const saveFormRef = useCallback(node => {
    if (node !== null) {
      setFormRef(node);
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    if (username && password) {
      this.props.login(username, password);
      return <Redirect to="/target" />;
    }
  }
  const enviar_form = values => {
    notification.open({
      message: "Cargando",
      description: "Este proceso puede demorar unos segundos",
      icon: <Icon type="loading" style={{ color: "#4caf50" }} />,
      top: 70
    });

    console.log(values);
    // Metodo para enviar el form de contacto
    axios
      .post(`${API_URL}/contacthome`, {
        name: values.name,
        emailcontact: values.email,
        telefono: values.phone,
        message: values.message
      })
      .then(response => {
        notification.destroy();
        notification.open({
          message: "Enviado",
          icon: <Icon type="check-circle" style={{ color: "#4caf50" }} />,
          top: 70
        });
      });
  };

  useEffect(() => {}, []);

  return (
    <div className="shards-app-promo-page--1">
      <div className="section welcome">
        <Navbar type="dark" expand="md">
          <div className="container-fluid">
            <NavbarToggler onClick={() => setCollapseOpen(!collapseOpen)} />
            <Collapse open={collapseOpen} navbar>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() =>
                      scroller.scrollTo("beneficios", {
                        duration: 500,
                        delay: 50,
                        smooth: true,
                        offset: -10
                      })
                    }
                  >
                    Beneficios socios
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() =>
                      scroller.scrollTo("red", {
                        duration: 500,
                        delay: 50,
                        smooth: true,
                        offset: -10
                      })
                    }
                  >
                    Nuestra red
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() =>
                      scroller.scrollTo("servicios", {
                        duration: 500,
                        delay: 50,
                        smooth: true,
                        offset: -10
                      })
                    }
                  >
                    Nuestros Servicios
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    onClick={() =>
                      scroller.scrollTo("about", {
                        duration: 500,
                        delay: 50,
                        smooth: true,
                        offset: -10
                      })
                    }
                  >
                    Quienes somos
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() =>
                      scroller.scrollTo("contacto", {
                        duration: 500,
                        delay: 50,
                        smooth: true,
                        offset: -10
                      })
                    }
                    href="#"
                  >
                    Contacto
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>

        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 d-flex flex-column mt-4">
              <img src="./img/logo-admin.svg" style={{ maxHeight: "150px" }} />
              <p
                className="text-white mt-4 mb-4 text-center"
                style={{ fontSize: "16px" }}
              >
                La aplicación exclusiva para que los socios de la Cámara del
                Comercio Automotor lleguen a mas clientes y aumenten sus ventas.
                Totalmente segura y gratuita.
              </p>
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="col-md-4 btn btn-secondary mr-2"
                  onClick={() =>
                    scroller.scrollTo("contacto", {
                      duration: 500,
                      delay: 50,
                      smooth: true,
                      offset: -10
                    })
                  }
                >
                  Hacete socio
                </button>
                <button
                  className="col-md-4 btn btn-primary ml-2"
                  onClick={() => setVisible(true)}
                >
                  Ingresar
                </button>
              </div>
              <ModalForm
                ref={saveFormRef}
                visible={visible}
                onCancel={() => setVisible(false)}
                onCreate={() => handleCreate()}
              />
            </div>
          </div>
        </div>
      </div>
      <Element name="beneficios">
        <div className="section beneficios">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 offset-md-1">
                <h3 className="section-title mb-5 mt-5">
                  Subite a nuestros autos
                </h3>
                <div className="feature d-flex">
                  <div className="icon text-white bg-primary mr-3">
                    <i className="material-icons">group_add</i>
                  </div>
                  <div>
                    <h5>Llegá a mas clientes</h5>
                    <p className="mb-4">
                      Manejá tu stock y multipublicá tus vehículos en los
                      principales sitios de ventas y redes sociales.
                    </p>
                  </div>
                </div>
                <div className="feature d-flex">
                  <div className="icon text-white bg-primary mr-3">
                    <i className="material-icons">queue_play_next</i>
                  </div>
                  <div>
                    <h5>Adoptá nuevas tecnologías</h5>
                    <p className="mb-4">
                      Todos los usuarios podrán contar con un sitio web
                      actualizado, dinámico y que posiciona muy bien en las
                      búsquedas de Google.
                    </p>
                  </div>
                </div>
                <div className="feature d-flex">
                  <div className="icon text-white bg-primary mr-3">
                    <i className="material-icons">enhanced_encryption</i>
                  </div>
                  <div>
                    <h5>Generá mas ingresos</h5>
                    <p className="mb-4">
                      Cerrá acuerdos con bancos y compañías de seguros
                      confiables.
                    </p>
                  </div>
                </div>
                <div className="feature d-flex mb-5">
                  <div className="icon text-white bg-primary mr-3">
                    <i className="fa fa-globe"></i>
                  </div>
                  <div>
                    <h5>Mejorá la gestión</h5>
                    <p className="mb-4">
                      Solicitá tus informes y comprá formularios con importantes
                      rebajas.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 features-bg"></div>
            </div>
          </div>
        </div>
      </Element>
      <Element name="about">
        <div className="subscribe section bg-dark">
          <div className="container">
            <div className="row mb-5 mt-5">
              <div className="col-md-6 d-flex justify-content-center">
                <img src="./img/logo-admin.svg" style={{ maxWidth: "70%" }} />
              </div>
              <div className="col-md-6">
                <p className="t-1 text-centered">
                  <strong>nuestrosautos</strong> es una plataforma digital
                  totalmente gratuita para los socios de la Cámara del Comercio
                  Automotor y pueden comenzar a utilizarlo realizando un simple
                  contacto con el equipo de soporte de la CCA, llamando al
                  4824-7272 o completando el <br />
                  <a href="#contacto" className="link">
                    formulario de contacto
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Element>
      <Element name="servicios">
        <div className="blog section">
          <h3 className="section-title">
            Servicios que brindamos a nuestros socios
          </h3>
          <div className="container">
            <div className="row">
              <div className="card-deck">
                <div className="col-md-12 col-lg-4">
                  <div className="card mb-4">
                    <img
                      className="card-img-top"
                      src="./img/img-01.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Habitualista</h4>
                      <p className="card-text">
                        Informate con todas las resoluciones, disposiciones y
                        mantené actualizado tu negocio.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-4">
                  <div className="card mb-4">
                    <img
                      className="card-img-top"
                      src="./img/img-02.jpg"
                      alt="Beneficion cca"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Formularios</h4>
                      <p className="card-text">
                        Accedé a todos los formularios e informes que necesitás
                        rápidamente y al mejor precio.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-4">
                  <div className="card mb-4">
                    <img
                      className="card-img-top"
                      src="./img/img-03.jpg"
                      alt="Card image cap"
                    />
                    <div className="card-body">
                      <h4 className="card-title">Notas Registrales</h4>
                      <p className="card-text">
                        Todas las Circulares de la Dirección Nacional de los
                        Registros Nacionales de la Propiedad del Automotor y de
                        Créditos Prendarios.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
      <Element name="red">
        <div className="testimonials section text-center bg-dark">
          <h3 className="section-title mb-2">Todos los portales en uno</h3>
          <p>
            Publicá tus vehículos desde un solo lugar en los sitios de internet
            mas visitados por tus potenciales clientes.
          </p>

          <div className="container mt-5">
            <div className="row">
              <div className="col-sm-6 col-md-2 testimonial text-center">
                <div className="avatar with-shadows mb-3 ml-auto mr-auto">
                  <img src="./img/autocosmos.png" alt="Autocosmos" />
                </div>
                <span className="text-muted d-block mb-2">
                  Autocosmos.com.ar
                </span>
              </div>
              <div className="col-sm-6 col-md-2 testimonial text-center">
                <div className="avatar with-shadows mb-3 ml-auto mr-auto">
                  <img src="./img/facebook.png" alt="Facebook" />
                </div>
                <span className="text-muted d-block mb-2">Facebook.com</span>
              </div>
              <div className="col-sm-6 col-md-2 testimonial text-center">
                <div className="avatar with-shadows mb-3 ml-auto mr-auto">
                  <img src="./img/dm.png" alt="Demotores" />
                </div>
                <span className="text-muted d-block mb-2">
                  Demotores.com.ar
                </span>
              </div>
              <div className="col-sm-6 col-md-2 testimonial text-center">
                <div className="avatar with-shadows mb-3 ml-auto mr-auto">
                  <img src="./img/mercadolibre.png" alt="Mercadolibre" />
                </div>
                <span className="text-muted d-block mb-2">
                  Mercadolibre.com.ar
                </span>
              </div>
              <div className="col-sm-6 col-md-2 testimonial text-center">
                <div className="avatar with-shadows mb-3 ml-auto mr-auto">
                  <img src="./img/olx.png" alt="Olx" />
                </div>
                <span className="text-muted d-block mb-2">Olx.com</span>
              </div>
              <div className="col-sm-6 col-md-2 testimonial text-center">
                <div className="avatar with-shadows mb-3 ml-auto mr-auto">
                  <img src="./img/na.png" alt="Nuestrosautos" />
                </div>
                <span className="text-muted d-block mb-2">
                  Nuestrosautos.com.ar
                </span>
              </div>
            </div>
          </div>
        </div>
      </Element>
      <Element name="contacto">
        <div className="contact section" id="contacto">
          <p className="text-center t-2">
            Comenzá hoy mismo a mejorar tu negocio acercándote a tus{" "}
            <strong>futuros clientes</strong>.
          </p>
          <div className="container mt-5">
            <div className="justify-content-md-center">
              <div className="contact-form col-sm-12 col-md-10 col-lg-7 p-4 m-auto card">
                <form onSubmit={props.handleSubmit(enviar_form)}>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="nombre">Su Concesionaria:</label>
                        <Field
                          name="name"
                          component={renderField}
                          id="nombre"
                          type="text"
                          className="form-control"
                          placeholder="Nombre de su concesionaria"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="telefono">Teléfono:</label>
                        <Field
                          name="phone"
                          id="telefono"
                          component={renderField}
                          type="tel"
                          className="form-control"
                          placeholder="Número telefónico"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <Field
                          name="email"
                          component={renderField}
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="mensaje">Mensaje</label>
                        <Field
                          name="message"
                          id="mensaje"
                          component={renderTextarea}
                          rows="5"
                          className="form-control mb-4"
                        />
                      </div>
                    </div>
                  </div>
                  <input
                    className="btn btn-primary d-flex ml-auto mr-auto"
                    type="submit"
                    value="Hacerme Socio"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </Element>
      <footer>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container">
            <a className="navbar-brand" href="#">
              NUESTROS AUTOS © 2020 UN SITIO DE LA CCA
            </a>
          </div>
        </nav>
      </footer>
    </div>
  );
};

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const po = reduxForm({
  form: "HomeForm",
  enableReinitialize: true,
  validate
})(NewHome);

const pox = connect(
  mapState,
  actionCreators
)(po);

export { pox as NewHome };
