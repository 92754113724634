import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { reduxForm} from "redux-form";
import { RedNuestrosAutos } from "../RedNuestrosAutos";
import { Table, Input, Space, Tabs, Radio } from 'antd';
import PageTitle from "../_components/PageTitle";
import {
  Row,
  Col,
  Card,
  Button,
  CardFooter,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import { SuperAdmin } from './SuperAdmin';
import Cookies from "universal-cookie";

const cookies = new Cookies();
const { TabPane } = Tabs;

const Consultas = props => {
  const { handleSubmit, handleChange,  pristine, reset, submitting } = props
 
  const [loading, setLoading] = useState(false);
  const [modalD, setModalD] = useState(false);

  const [mercadolibre, setMercadoLibre] = useState([]);
  const [nuestrosautos, setNuestrosAutos] = useState([]);
  const [autocosmos, setAutoCosmos] = useState([]);
  const [demotores, setDeMotores] = useState([]);

  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user')));
  const [dlz, setDlz] = useState([{}]);
  const [enabledDealers, setEnabledDealers] = useState([]);

  useEffect(() => {
   
   // Obtengo todos los items
   axios.get('https://nuestrosautos.cca.org.ar/api/dealer/1/questions' + "?token=" +
   props.user.token).then(response => {
     // Guardo NA
     if(response.data.na) {
      setNuestrosAutos(Object.values(response.data.na))
     }
     // Guardo DM
     if(response.data.dm) {
      setDeMotores(Object.values(response.data.dm))
     }
     // Guardo AC
     if(response.data.ac) {
      setAutoCosmos(Object.values(response.data.ac))
     }
   })

   // Obtengo todos los items
   axios.get('https://nuestrosautos.cca.org.ar/conversations/' + cookies.get("dealer").id + "?token=" +
   props.user.token).then(response => {
     // Guardo ML
     if(response.data.mascaras) {
      setMercadoLibre(Object.values(response.data.mascaras))
     }
   })

  
  }, []); 
  
  function routeChange(id) 
  {
      let path = "/consultas/view/" + id;
      props.history.push(path);
  }
 

  if (loading == true) {
    return (<div style={{height:'500px', textAlign:'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}><img src="/loading.gif" style={{maxWidth:'200px', width:'100%'}}/></div>);
  }

  const columns = [
    {
      title: 'Publicación',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <a style={{color: '#1890ff', fontWeight: 500}} href={record.link}>{text}</a>
    },
    {
      title: 'Preguntador',
      dataIndex: 'nombre',
      key: 'nombre'
    },
    {
      title: 'Telefono',
      dataIndex: 'telefono',
      key: 'telefono'
     
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => <a className={'btn btn-white'} href={'/consultas/view/' + record.id}>Más detalles</a>
    },
  ];

  const columnsML = [
    {
      title: 'Publicación',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => <a style={{color: '#1890ff', fontWeight: 500}} href={record.publication.permalink}>{record.publication.data.title}</a>
    },
    {
      title: 'Preguntador',
      dataIndex: 'nombre',
      key: 'nombre',
      render: (text, record) => <>{record.sender.data.nombre + ' ' + record.sender.data.apellido}</>
    },
    {
      title: 'Telefono',
      dataIndex: 'telefono',
      key: 'telefono',
      render: (text, record) => <>{record.sender.data.phone}</>
    }, 
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => <a className={'btn btn-primary text-white'} onClick={() => routeChange(record.conversation_id)}>Más detalles</a>
    },
  ];

  return (
   <div>
     <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
            <PageTitle sm="4" title="Consultas" className="text-sm-left" /> 
            
            <Col sm="6"></Col>
            <Col sm="2" className="text-sm-right">
            <span onClick={() => setModalD(true)} class="material-icons">
            more_vert
            </span>
            </Col>
          </Row>

       <Card small className="card-post mb-4 ">
            
                <CardBody className="border-top">
        <Tabs defaultActiveKey="1" type="card" size={'small'}>
          <TabPane tab="MercadoLibre" key="1">
            <Table columns={columnsML} dataSource={mercadolibre} rowClassName={(record, index) => record.ultima_leida === null ? 'normalData' :  'bg-leido'}/>
          </TabPane>
          <TabPane tab="NuestrosAutos" key="2">
            <Table columns={columns} dataSource={nuestrosautos} />
          </TabPane>
          <TabPane tab="AutoCosmos" key="3">
            <Table columns={columns} dataSource={autocosmos} />
          </TabPane>
          <TabPane tab="DeMotores" key="4">
            <Table columns={columns} dataSource={demotores} />
          </TabPane>
      </Tabs>
    
                </CardBody>
              </Card>


              <Modal size="lg" open={modalD} toggle={() => console.log("Toggle")}>
            <ModalHeader>
                Asignaciones Notificaciones
                <i
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "15px",
                    fontSize: "25px",
                    cursor: "pointer"
                  }}
                  class="material-icons"
                  onClick={() => setModalD(false)}
                >
                  clear
                </i>
              </ModalHeader>
              <ModalBody>
                
                    <SuperAdmin userazo={userData}></SuperAdmin>
                  
              </ModalBody>
            </Modal>
   </div>
  )
  
}


function mapState(state, props) {
   
    const { users, authentication, vehicles, adminuser } = state;
  
    const { user } = authentication;

    var initl = {};

    return { user, users, adminuser, initialValues: initl};
  }
  const actionCreators = {
  
  };

const po = reduxForm({
  form: 'Consultas', 
  enableReinitialize: true
})(Consultas);

const pox = connect(
    mapState,
    actionCreators
  )(po);
  
export { pox as Consultas };