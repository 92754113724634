/*eslint-disable */
import React, { useState, useEffect } from "react";
import { API_URL } from "../_config";
import { compose } from "redux";
import { connect } from "react-redux";
import CheckBoxSimple from "../_common/CheckBoxSimple";
import SelectSimple from "../_common/SelectSimple";
import PolyLoader from "../_components/PolyLoader";
import SelectDynamic from "../_common/SelectDynamic";
import { TextareaSimple } from "../_common";
import Validate from "../_common/Validate";
import InputField from "../_common/InputField";
import ImagesUploader from "react-images-uploader";
import { store } from "../_helpers";
import "react-images-uploader/styles.css";
import "react-images-uploader/font.css";
import FacebookLibrary from "../_common/FacebookLibrary";
import Script from "react-load-script";
import { EditVehicles } from "../Vehicles";
import { PREAPI_URL } from "../_config";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  Modal,
  ModalBody,
  ModalHeader,
  CardImg,
  FormCheckbox,
  Container,
  FormTextarea,
  CardHeader,
  CardBody,
  Button,
  CardTitle,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  Alert,
  Tooltip,
} from "shards-react";
import ResolveConflict from "./ResolveConflict/ResolveConflict";

import openNotificationWithIcon from "../_common/OpenNotificationWithIcon";
import PageTitle from "../_components/PageTitle";
import { vehiclesActions, alertActions, notificacionsActions } from "../_actions";
import "./VehiclesPublish.css";
//import { PublishInput } from "./PublishInput";
import { Drawer, notification, Icon } from "antd";
import Cookies from "universal-cookie";

function PublishInput(props) {
  const { prop } = props;
  const cookies = new Cookies();
  const { initialValues } = prop;

  const { publish } = initialValues;
  const [catevisible, setCatevisible] = useState(false);
  const [listingTypes, setListingTypes] = useState("");
  const [deAutosListingTypes, setDeAutosListingTypes] = useState(false);

  const [publishsIns, setPublish] = useState(new Map());
  const [config, setConfig] = useState([]);
  const [configBool, setConfigBool] = useState(false);

  const [idVehicle, setIdVehicle] = useState();
  const [conflict, setConflict] = useState(false); // activa resolucion de conflicto
  const [configConflict, setConfigConflict] = useState();
  const [cancelRC, setCancelRC] = useState(false);
  const [colActive, setColActive] = useState(true);
  const [enableFB, setEnableFB] = useState(false);
  const [pagesFB, setPagesFB] = useState([]);
  //const [pagesIns, setPagesIn] = useState([]);

  const [selectedPageFBid, setSelectedPageFBid] = useState("");
  const [selectedPageFBtoken, setSelectedPageFBtoken] = useState("");
  const [selectedPageFBnote, setSelectedPageFBnote] = useState("");

  const [enableIN, setEnableIN] = useState(false);
  const [pagesIN, setPagesIN] = useState([]);
  const [selectedPageINid, setSelectedPageINid] = useState("");
  const [selectedPageINtoken, setSelectedPageINtoken] = useState("");
  const [selectedPageINnote, setSelectedPageINnote] = useState("");


  const [olxTextModal, setOlxTextModal] = useState(false);
  const [listingLaVoz, setListingLaVoz] = useState(false);
  const [OLXtext, setOLXtext] = useState("");
  const [OLXtextValidation, setOLXtextValidation] = useState(false);
  const [MemoCharge, setMemoCharge] = useState(false);
  const [toggleOpen, setToogleOpen] = useState(false);

  const handleLoginResponse = function (res) {
    window.FB.api("/me/accounts", function (result) {
      console.log(result);
    });
  };

  async function deshacerConflicto($portal) {
    switch ($portal) {
      case "mercadolibre":
        axios({
          method: "get",
          url:
            API_URL +
            "api/dealer/" +
            cookies.get("dealer").id +
            "/mercadolibre/vehicle/" +
            props.vehiculo +
            "/deshacer_conflicto?token=" +
            props.user.token,
        }).then((response) => {
          if (response.data == 0) {
            setCancelRC(false);
            props.refreshMovida(
              true,
              "success",
              "Listo!",
              "ya deshizo la resolución de conflictos",
            );
          }
        });

        break;
      case "da":
        axios({
          method: "get",
          url:
            API_URL +
            "api/dealer/" +
            cookies.get("dealer").id +
            "/da/vehicle/" +
            props.vehiculo +
            "/deshacer_conflicto?token=" +
            props.user.token,
        }).then((response) => {
          if (response.data == 0) {
            setCancelRC(false);
            props.refreshMovida(
              true,
              "success",
              "Listo!",
              "ya deshizo la resolución de conflictos",
            );
          }
        });

        break;
      case "ol":
        axios({
          method: "get",
          url:
            API_URL +
            "api/dealer/" +
            cookies.get("dealer").id +
            "/olx/vehicle/" +
            props.vehiculo +
            "/deshacer_conflicto?token=" +
            props.user.token,
        })
          .then((response) => {
            if (response.data == 0) {
              setColActive(true);
              props.refreshMovida(
                true,
                "success",
                "Listo!",
                "ya deshizo la resolución de conflictos",
              );
            } else {
              props.refreshMovida(
                true,
                "error",
                "Ocurrio un problema",
                "Error al Deshacer la Resolución de Conflictos OLX",
              );
            }
          })
          .catch((err) => {
            props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
          });

        break;
      case "ac":
        axios({
          method: "get",
          url:
            API_URL +
            "api/dealer/" +
            cookies.get("dealer").id +
            "/autocosmos/vehicle/" +
            props.vehiculo +
            "/deshacer_conflicto?token=" +
            props.user.token,
        })
          .then((response) => {
            if (response.data == 0) {
              setColActive(true);
              props.refreshMovida(
                true,
                "success",
                "Listo!",
                "ya deshizo la resolución de conflictos",
              );
            } else {
              props.refreshMovida(
                true,
                "error",
                "Ocurrio un problema",
                "Error al Deshacer la Resolución de Conflictos Autocosmos",
              );
            }
          })
          .catch((err) => {
            props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
          });

        break;
      case "dm":
        axios({
          method: "get",
          url:
            API_URL +
            "api/dealer/" +
            cookies.get("dealer").id +
            "/demotores/vehicle/" +
            props.vehiculo +
            "/deshacer_conflicto?token=" +
            props.user.token,
        })
          .then((response) => {
            if (response.data == 0) {
              setColActive(true);
              props.refreshMovida(
                true,
                "success",
                "Listo!",
                "ya deshizo la resolución de conflictos",
              );
            } else {
              props.refreshMovida(
                true,
                "error",
                "Ocurrio un problema",
                "Error al Deshacer la Resolución de Conflictos Demotores",
              );
            }
          })
          .catch((err) => {
            props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
          });

        break;
    }
  }

  async function getDataML($id) {
    const res = await axios.post(
      API_URL +
      "api/dealer/" +
      cookies.get("dealer").id +
      "/mercadolibre/vehicle/" +
      props.vehiculo +
      "/listing-type?token=" +
      props.user.token,
      { listing_type: $id },
    );
    return await res;
  }

  function publishML($id) {
    setCatevisible(false);
    setMemoCharge(true);
    axios
      .post(
        API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/mercadolibre/vehicle/" +
        props.prop.vehiculo +
        "/listing-type?token=" +
        props.user.token,
        { listing_type: $id },
      )
      //getDataML($id)
      .then((res) => {
        //props.hideAlert();
        if (res.data.data != "OK") {
          // Resolucion de conflicto
          setConfigConflict({
            portal: "mercadolibre",
            field: "mercadolibre_id",
            listing: $id,
            result: res.data,
            state_publish: config,
          });
          setTimeout(() => {
            setConflict(true);
          }, 430);
          setMemoCharge(false);
          props.refreshMovida(false);
        } else {
          //  var publish =  $resource('/api/dealer/'+$scope.currentDealer.id+'/mercadolibre/vehicle/'+$scope.vehicleId+'/publish');
          axios
            .post(
              API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/mercadolibre/vehicle/" +
              props.vehiculo +
              "/publish?token=" +
              props.user.token,
              { listing_type: $id },
            )
            .then((res) => {
              //
              initialValues.publish.mercadolibre = true;
              setMemoCharge(false);
              props.refreshMovida(true, "success", "Listo!", "Ya está publicado en Mercadolibre.");
            })
            .catch((err) => {
              setMemoCharge(false);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
            });
        }
      })
      .catch((err) => {
        props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
      });
  }

  function select_page(event) {
    if (event.target.value == "nulo") {
    } else {
      var selected_page = JSON.parse(event.target.value);

      setSelectedPageFBid(selected_page.id);
      setSelectedPageFBtoken(selected_page.token);
    }
  }


  function select_pageIN(event) {
    if (event.target.value == "nulo") {
    } else {
      var selected_page = JSON.parse(event.target.value);

      setSelectedPageINid(selected_page.id);
      setSelectedPageINtoken(selected_page.token);
    }
  }

  function define_note(event) {
    console.log(props);
    setSelectedPageFBnote(event.target.value);
  }

  function define_noteIN(event) {
    console.log(props);
    setSelectedPageINnote(event.target.value);
  }

  function reportar_error(portal, mensaje) {
    console.log(props);

    var dato = JSON.parse(localStorage.getItem("user"));

    var user_id = dato.user.id;

    axios
      .post(PREAPI_URL + "ac_notificacions?token=" + store.getState().authentication.user.token, {
        title: "Ocurrió un error al eliminar en " + portal,
        text: mensaje + " Vehículo (" + props.prop.vehiculo + "): " + props.showVehicle.titleML,
        type: "error",
        leido: 0,
        destino: user_id,
        button: "",
      })
      .then((response) => {
        // Ok
        props.getNoti();
      });
  }

  function define_note_olx(event) {
    setOLXtext(event.target.value);
    let value = event.target.value;
    (value.includes("@") && value.includes(".com")) ||
      (value.includes("www") && value.includes(".com"))
      ? setOLXtextValidation(true)
      : setOLXtextValidation(false);
  }

  function subirFacebook() {
    if (selectedPageFBid == "" || selectedPageFBnote == "" || selectedPageFBtoken == "") {
      openNotificationWithIcon(
        "error",
        "Ooops...",
        "Debe completar todos los campos para continuar",
      );
    } else {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          axios({
            method: "post",
            url:
              PREAPI_URL +
              "api/" +
              "dealer/" +
              cookies.get("dealer").id +
              "/facebook/" +
              "vehicle/" +
              props.vehiculo +
              "/share?token_fb=" +
              window.FB.getAccessToken() +
              "&token=" +
              props.user.token,
            data: {
              page: {
                id: selectedPageFBid,
                token: selectedPageFBtoken,
                note: selectedPageFBnote,
              },
            },
          })
            .then((response) => {
              setEnableFB(false);
              setSelectedPageFBid("");
              setSelectedPageFBnote("");
              setSelectedPageFBtoken("");
              props.refreshMovida(
                true,
                "success",
                "¡Listo!",
                "Se ha publicado en Facebook exitosamente",
              );
              console.log(response.data);
            })
            .catch((error) => {
              props.refreshMovida(true, "error", "Ooops...", `${error.response.data}`);
            });
        } else {
          window.FB.login(function (response) {
            if (response.authResponse) {
              console.log(window.FB.getAccessToken());

              axios({
                method: "post",
                url:
                  PREAPI_URL +
                  "api/" +
                  "dealer/" +
                  cookies.get("dealer").id +
                  "/facebook/" +
                  "vehicle/" +
                  props.vehiculo +
                  "/share?token_fb=" +
                  window.FB.getAccessToken() +
                  "&token=" +
                  props.user.token,
                data: {
                  page: {
                    id: selectedPageFBid,
                    token: selectedPageFBtoken,
                    note: selectedPageFBnote,
                  },
                },
              })
                .then((response) => {
                  setEnableFB(false);
                  setSelectedPageFBid("");
                  setSelectedPageFBnote("");
                  setSelectedPageFBtoken("");
                  props.refreshMovida(
                    true,
                    "success",
                    "¡Listo!",
                    "Se ha publicado en Facebook exitosamente",
                  );
                  console.log(response.data);
                })
                .catch((error) => {
                  props.refreshMovida(true, "error", "Ooops...", `${error.response.data}`);
                });
            } else {
              props.refreshMovida(
                true,
                "error",
                "Ooops...",
                "No hemos podido obtener la autorización, por favor, intente más tarde",
              );
            }
          });
        }
      });
    }
  }
  function subirInstagram() {
    if (selectedPageINid == "" || selectedPageINnote == "" || selectedPageINtoken == "") {
      openNotificationWithIcon(
        "error",
        "Ooops...",
        "Debe completar todos los campos para continuar",
      );
    } else {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          axios({
            method: "post",
            url:
              PREAPI_URL +
              "api/" +
              "dealer/" +
              cookies.get("dealer").id +
              "/instagram/" +
              "vehicle/" +
              props.vehiculo +
              "/share?token_fb=" +
              window.FB.getAccessToken() +
              "&token=" +
              props.user.token,
            data: {
              page: {
                id: selectedPageINid,
                token: selectedPageINtoken,
                note: selectedPageINnote,
              },
            },
          })
            .then((response) => {
              setEnableIN(false);
              setSelectedPageINid("");
              setSelectedPageINnote("");
              setSelectedPageINtoken("");
              props.refreshMovida(
                true,
                "success",
                "¡Listo!",
                "Se ha publicado en Instagram exitosamente",
              );
              console.log(response.data);
            })
            .catch((error) => {
              props.refreshMovida(true, "error", "Ooops...", `${error.response.data}`);
            });
        } else {
          window.FB.login(function (response) {
            if (response.authResponse) {
              console.log(window.FB.getAccessToken());

              axios({
                method: "post",
                url:
                  PREAPI_URL +
                  "api/" +
                  "dealer/" +
                  cookies.get("dealer").id +
                  "/instagram/" +
                  "vehicle/" +
                  props.vehiculo +
                  "/share?token_fb=" +
                  window.FB.getAccessToken() +
                  "&token=" +
                  props.user.token,
                data: {
                  page: {
                    id: selectedPageINid,
                    token: selectedPageINtoken,
                    note: selectedPageINnote,
                  },
                },
              })
                .then((response) => {
                  setEnableIN(false);
                  setSelectedPageINid("");
                  setSelectedPageINnote("");
                  setSelectedPageINtoken("");
                  props.refreshMovida(
                    true,
                    "success",
                    "¡Listo!",
                    "Se ha publicado en Instagram exitosamente",
                  );
                  console.log(response.data);
                })
                .catch((error) => {
                  props.refreshMovida(true, "error", "Ooops...", `${error.response.data}`);
                });
            } else {
              props.refreshMovida(
                true,
                "error",
                "Ooops...",
                "No hemos podido obtener la autorización, por favor, intente más tarde",
              );
            }
          });
        }
      });
    }
  }
  function toglemovidaOLX() {
    setOlxTextModal(false);
  }

  function publicarOLX() {
    setOlxTextModal(false);
    setMemoCharge(true);

    axios
      .post(
        API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/olx/vehicle/" +
        props.vehiculo +
        "/publish?token=" +
        props.user.token,
        { noteOLX: OLXtext },
      )
      .then((res) => {
        setMemoCharge(false);
        if (res.data != "OK") {
          setConfigConflict({
            portal: "olx",
            field: "olx_id",

            result: res.data,
            state_publish: config,
          });
          setTimeout(() => {
            setConflict(true);
          }, 430);
          console.log("OLX !");
        } else {
          setColActive(true);
          props.refreshMovida(
            true,
            "success",
            "Listo!",
            "Publicando en Olx, recuerde que las publicaciones de olx demoran 24 hs en impactar.",
          );
        }
      })
      .catch((err) => {
        setMemoCharge(false);
        props.refreshMovida(true, "error", "ocurrio un problema", `${err.response.data}`);
      });
  }

  function toglemovida() {
    setEnableFB(!enableFB);
    setSelectedPageFBid("");
    setSelectedPageFBnote("");
    setSelectedPageFBtoken("");
  }

  function toglemovidaIN() {
    setEnableIN(!enableIN);
    setSelectedPageINid("");
    setSelectedPageINnote("");
    setSelectedPageINtoken("");
  }



  function publishLaVoz($id) {
    setCatevisible(false);
    setListingLaVoz(false);
    setMemoCharge(true);

    axios
      .post(
        API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/lavoz/vehicle/" +
        props.vehiculo +
        "/publish?token=" +
        props.user.token,
        { listing_type: $id },
      )
      .then((res) => {
        setMemoCharge(false);
        if (res.data.ms != "ok") {
          setMemoCharge(false);
          setConfigConflict({
            portal: "lavoz",
            field: "lavoz_id",
            listing: $id,
            result: res.data,
            state_publish: config,
          });
          setTimeout(() => {
            setConflict(true);
          }, 430);
          console.log("LaVoz!");
        } else {
          setMemoCharge(false);
          initialValues.publish.laVoz = false;
          setColActive(true);
          props.refreshMovida(true, "success", "Listo!", "Publicado en laVoz.");
        }
      })
      .catch((err) => {
        setMemoCharge(false);
        props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
      });
  }


  function publishDA(pack) {
    setMemoCharge(true);
    axios
      .post(
        API_URL +
        "api/dealer/" +
        cookies.get("dealer").id +
        "/deautos/vehicle/" +
        props.vehiculo +
        "/publish?token=" +
        props.user.token,
        {
          pack_id: pack,
        },
      )
      .then((res) => {
        setMemoCharge(false);
        if (res.data.ms == "OK" || res.data.ms == "ok" || res.data == "Ok" || res.data == "OK" || res.statusText === "OK" || res.statusText === "ok") {
          setMemoCharge(false);
          // openNotificationWithIcon("success", "Listo!", "Publicando en DeAutos.");
          initialValues.publish.deAutos = false;
          setColActive(true);
          props.refreshMovida(true, "success", "Listo!", "Publicando en DeAutos.");
          setCatevisible(false)
        } else {
          setMemoCharge(false);
          setConfigConflict({
            portal: "deautos",
            field: "deautos_id",
            year: props.year,
            result: res.data,
            state_publish: config,
          });
          setTimeout(() => {
            setConflict(true);
          }, 430);
        }
      })
      .catch((err) => {
        setMemoCharge(false);
        props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
      });
  }

  function changePublish(id, status) {
    localStorage.removeItem("vehicle_list");

    setMemoCharge(true);

    switch (id) {
      case "mercadolibre":
        console.log(status);
        if (status == true) {
          axios({
            method: "get",
            url: API_URL + "api/mercadolibre/listing-types?token=" + props.user.token,
          }).then((response) => {
            setListingTypes(response.data);
            setCatevisible(status);
            setMemoCharge(false);
          });
        } else {
          axios({
            method: "delete",
            url:
              API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/mercadolibre/vehicle/" +
              props.vehiculo +
              "/listing-type?token=" +
              props.user.token,
          })
            .then((response) => {
              initialValues.publish.mercadolibre = false;
              setColActive(true);
              setMemoCharge(false);
              props.refreshMovida(
                true,
                "success",
                "Listo!",
                "Hemos eliminado tu publicación en MercadoLibre exitosamente.",
              );
            })
            .catch((err) => {
              setMemoCharge(false);
              reportar_error("ml", err.response.data);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
            });
        }

        //props.showAlert;
        // props.showAlert();
        break;
      case "olx":
        if (status == true) {
          setMemoCharge(false);
          setOlxTextModal(true);
        } else {
          axios({
            method: "delete",
            url:
              API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/olx/vehicle/" +
              props.vehiculo +
              "/unpublish?token=" +
              props.user.token,
          })
            .then((response) => {
              initialValues.publish.olx = false;
              setColActive(true);
              setMemoCharge(false);
              props.refreshMovida(
                true,
                "success",
                "Listo!",
                "Hemos eliminado tu publicación en OLX exitosamente.",
              );
            })
            .catch((err) => {
              setMemoCharge(false);
              reportar_error("olx", err.response.data);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
            });
        }
        break;
      case "demotores":
        if (status == true) {
          axios
            .post(
              API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/demotores/vehicle/" +
              props.vehiculo +
              "/publish?token=" +
              props.user.token,
            )
            .then((res) => {
              setMemoCharge(false);
              if (
                res.data.ms == "OK" ||
                res.data.ms == "ok" ||
                res.data == "ok" ||
                res.data == "OK"
              ) {
                setMemoCharge(false);
                initialValues.publish.deMotores = false;
                setColActive(true);
                props.refreshMovida(true, "success", "Listo!", "Publicando en Demotores.");
              } else {
                setMemoCharge(false);
                setConfigConflict({
                  portal: "demotores",
                  field: "demotores_id",
                  result: res.data,
                  state_publish: config,
                });
                setTimeout(() => {
                  setConflict(true);
                }, 430);
                console.log("Demotores!");
              }
            })
            .catch((err) => {
              setMemoCharge(false);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data || err.response.data.error || err.response.data.message}`);
            });
        } else {
          axios({
            method: "delete",
            url:
              API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/demotores/vehicle/" +
              props.vehiculo +
              "/unpublish?token=" +
              props.user.token,
          })
            .then((response) => {
              initialValues.publish.deMotores = false;
              setColActive(true);
              setMemoCharge(false);

              props.refreshMovida(
                true,
                "success",
                "Listo!",
                "Hemos eliminado tu publicación en deMotores exitosamente.",
              );
            })
            .catch((err) => {
              setMemoCharge(false);
              reportar_error("dm", err.response.data);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data || err.response.data.error || err.response.data.message}`);
            });
        }
        break;
      case "deautos":
        if (status == true) {
          setDeAutosListingTypes(true);
          setCatevisible(status);
          setMemoCharge(false);
        } else {
          axios({
            method: "delete",
            url:
              API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/deautos/vehicle/" +
              props.vehiculo +
              "/unpublish?token=" +
              props.user.token,
          })
            .then((response) => {
              initialValues.publish.deAutos = false;
              setColActive(true);
              setMemoCharge(false);
              props.refreshMovida(
                true,
                "success",
                "Listo!",
                "Hemos eliminado tu publicación en deAutos.",
              );
            })
            .catch((err) => {
              setMemoCharge(false);
              reportar_error("da", err.response.data);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
            });
        }
        break;
      case "lavoz":
        if (status == true) {
          axios({
            method: "get",
            url:
              API_URL +
              "api/lavoz/listing-types/" +
              cookies.get("dealer").id +
              "?token=" +
              props.user.token,
          })
            .then((response) => {
              setListingTypes([]);
              setListingTypes(response.data.ms.destaques);
              setCatevisible(status);
              setListingLaVoz(true);
              setMemoCharge(false);
            })
            .catch((err) => {
              // openNotificationWithIcon("error", "Ocurrio un problema", `${err.response.data}`);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
              setMemoCharge(false);
            });
        } else {
          axios({
            method: "post",
            url:
              API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/lavoz/vehicle/" +
              props.vehiculo +
              "/unpublish?token=" +
              props.user.token,
          })
            .then((response) => {
              initialValues.publish.lavoz = false;
              setColActive(true);
              setMemoCharge(false);
              props.refreshMovida(
                true,
                "success",
                "Listo!",
                "Hemos eliminado tu publicación en laVoz exitosamente.",
              );
            })
            .catch((err) => {
              setMemoCharge(false);
              reportar_error("lavoz", err.response.data);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
            });
        }
        break;
      case "facebook":
        window.FB.init({
          appId: "857034091332515",
          autoLogAppEvents: true,
          xfbml: true,
          status: true,
          version: "v4.0",
          cookie: true,
        });
        setMemoCharge(false);
        if (status == true) {
          window.FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
              axios({
                method: "get",
                url:
                  PREAPI_URL +
                  "api/" +
                  "facebook/" +
                  "pages_react?token_fb=" +
                  window.FB.getAccessToken() +
                  "&token=" +
                  props.user.token,
              })
                .then((response) => {
                  setPagesFB(response.data);
                  setEnableFB(true);
                })
                .catch((error) => {
                  props.refreshMovida(true, "error", "Ooops...", `${error.response.data}`);
                });
            } else {
              window.FB.login(function (response) {
                if (response.authResponse) {
                  console.log(window.FB.getAccessToken());

                  axios({
                    method: "get",
                    url:
                      PREAPI_URL +
                      "api/" +
                      "facebook/" +
                      "pages_react?token_fb=" +
                      window.FB.getAccessToken() +
                      "&token=" +
                      props.user.token,
                  })
                    .then((response) => {
                      setPagesFB(response.data);
                      setEnableFB(true);
                    })
                    .catch((error) => {
                      props.refreshMovida(true, "error", "Ooops...", `${error.response.data}`);
                    });
                } else {
                  props.refreshMovida(
                    true,
                    "error",
                    "Ooops...",
                    "No hemos podido obtener la autorización por favor, intente más tarde",
                  );
                }
              });
            }
          });
        } else {
          window.FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
              axios({
                method: "get",
                url:
                  PREAPI_URL +
                  "api/" +
                  "facebook/" +
                  "pages_react?token_fb=" +
                  window.FB.getAccessToken() +
                  "&token=" +
                  props.user.token,
              }).then((response) => {
                setMemoCharge(false);
                response.data.forEach(function (userPage) {
                  axios({
                    method: "delete",
                    url:
                      PREAPI_URL +
                      "api/" +
                      "dealer/" +
                      cookies.get("dealer").id +
                      "/facebook/" +
                      "vehicle/" +
                      props.vehiculo +
                      "/delete?token_fb=" +
                      window.FB.getAccessToken() +
                      "&token=" +
                      props.user.token,
                  })
                    .then((response) => {
                      console.log(response.data);
                      props.refreshMovida();
                    })
                    .catch((error) => {
                      props.refreshMovida(
                        true,
                        "error",
                        "Ooops...",
                        `${error.response.data.message}`,
                      );
                    });
                });
              });
            } else {
              window.FB.login(function (response) {
                if (response.authResponse) {
                  console.log(window.FB.getAccessToken());

                  axios({
                    method: "get",
                    url:
                      PREAPI_URL +
                      "api/" +
                      "facebook/" +
                      "pages_react?token_fb=" +
                      window.FB.getAccessToken() +
                      "&token=" +
                      props.user.token,
                  }).then((response) => {
                    setMemoCharge(false);
                    response.data.forEach(function (userPage) {
                      axios({
                        method: "delete",
                        url:
                          PREAPI_URL +
                          "api/" +
                          "dealer/" +
                          cookies.get("dealer").id +
                          "/facebook/" +
                          "vehicle/" +
                          props.vehiculo +
                          "/delete?token_fb=" +
                          window.FB.getAccessToken() +
                          "&token=" +
                          props.user.token,
                      })
                        .then((response) => {
                          setMemoCharge(false);
                          console.log(response.data);
                          props.refreshMovida(false);
                        })
                        .catch((error) => {
                          setMemoCharge(false);
                          reportar_error("fb", error.response.data.message);
                          props.refreshMovida(
                            true,
                            "error",
                            "Ooops...",
                            `${error.response.data.message}`,
                          );
                        });
                    });
                  });
                } else {
                  console.log("User cancelled login or did not fully authorize.");
                  props.refreshMovida();
                }
              });
            }
          });

          //////////////
        }
        break;
      case "autocosmos":
        //var publishNuestrosAutos = '/api/dealer/'+$scope.currentDealer.id+'/autocosmos/vehicle/'+$scope.vehicleId+'/publish';
        if (status == true) {
          axios
            .post(
              API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/autocosmos/vehicle/" +
              props.vehiculo +
              "/publish?token=" +
              props.user.token,
            )
            .then((res) => {
              setMemoCharge(false);
              console.log("LOS QUE VIENE||", res.data);
              if (res.data.data !== "OK") {
                setConfigConflict({
                  portal: "autocosmos",
                  field: "autocosmos_id",
                  result: res.data,
                  state_publish: config,
                });
                setTimeout(() => {
                  setConflict(true);
                }, 430);
                console.log("autocosmos!");
              } else {
                setMemoCharge(false);

                setColActive(true);
                initialValues.publish.autoCosmos = true;
                props.refreshMovida(true, "success", "Listo!", "Publicando en Autocosmos.");
              }
            })
            .catch((err) => {
              setMemoCharge(false);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
            });
        } else {
          axios({
            method: "delete",
            url:
              API_URL +
              "api/dealer/" +
              cookies.get("dealer").id +
              "/autocosmos/vehicle/" +
              props.vehiculo +
              "/unpublish?token=" +
              props.user.token,
          })
            .then((response) => {
              initialValues.publish.autocosmos = false;
              setColActive(true);
              setMemoCharge(false);
              props.refreshMovida(
                true,
                "success",
                "Listo!",
                "Hemos eliminado tu publicación en AutoCosmos exitosamente.",
              );
            })
            .catch((err) => {
              setMemoCharge(false);
              reportar_error("ac", err.response.data);
              props.refreshMovida(true, "error", "Ocurrio un problema", `${err.response.data}`);
            });
        }

        break;

      case "instagram":

        window.FB.init({
          appId: "857034091332515",
          autoLogAppEvents: true,
          xfbml: true,
          status: true,
          version: "v14.0",
          cookie: true,
        });
        setMemoCharge(false);
        if (status == true) {
          window.FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
              axios({
                method: "get",
                url:
                  PREAPI_URL +
                  "instagram/acount_list" +
                  "?access_token=" +
                  window.FB.getAccessToken() +
                  "&id_fb=" + response.authResponse.userID +
                  "&token=" +
                  props.user.token,
              })
                .then((response) => {
                  setPagesIN(response.data);
                  setEnableIN(true);
                })
                .catch((error) => {
                  props.refreshMovida(true, "error", "Ooops...", `${error.response.data}`);
                });
            } else {
              window.FB.login(function (response) {
                if (response.authResponse) {
                  console.log(window.FB.getAccessToken());

                  axios({
                    method: "get",
                    url:
                      PREAPI_URL +
                      "instagram/acount_list" +
                      "?access_token=" +
                      window.FB.getAccessToken() +
                      "&id_fb=" + response.authResponse.userID +
                      "&token=" +
                      props.user.token,
                  })
                    .then((response) => {
                      setPagesIN(response.data);
                      setEnableIN(true);
                    })
                    .catch((error) => {
                      props.refreshMovida(true, "error", "Ooops...", `${error.response.data}`);
                    });
                } else {
                  props.refreshMovida(
                    true,
                    "error",
                    "Ooops...",
                    "No hemos podido obtener la autorización por favor, intente más tarde",
                  );
                }
              });
            }
          });
        } else {
          window.FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
              axios({
                method: "get",
                url:
                  PREAPI_URL +
                  "instagram/acount_list" +
                  "?access_token=" +
                  window.FB.getAccessToken() +
                  "&id_fb=" + response.authResponse.userID +
                  "&token=" +
                  props.user.token,
              }).then((response) => {
                setMemoCharge(false);
                response.data.forEach(function (userPage) {
                  axios({
                    method: "delete",
                    url:
                      PREAPI_URL +
                      "api/" +
                      "dealer/" +
                      cookies.get("dealer").id +
                      "/instagram/" +
                      "vehicle/" +
                      props.vehiculo +
                      "/delete?token_fb=" +
                      window.FB.getAccessToken() +
                      "&token=" +
                      props.user.token,
                  })
                    .then((response) => {
                      console.log(response.data);
                      props.refreshMovida();
                    })
                    .catch((error) => {
                      props.refreshMovida(
                        true,
                        "error",
                        "Ooops...",
                        `${error.response.data.message}`,
                      );
                    });
                });
              });
            } else {
              window.FB.login(function (response) {
                if (response.authResponse) {
                  console.log(window.FB.getAccessToken());

                  axios({
                    method: "get",
                    url:
                      PREAPI_URL +
                      "instagram/acount_list" +
                      "?access_token=" +
                      window.FB.getAccessToken() +
                      "&id_fb=" + response.authResponse.userID +
                      "&token=" +
                      props.user.token
                  }).then((response) => {
                    setMemoCharge(false);
                    response.data.forEach(function (userPage) {
                      axios({
                        method: "delete",
                        url:
                          PREAPI_URL +
                          "api/" +
                          "dealer/" +
                          cookies.get("dealer").id +
                          "/instagram/" +
                          "vehicle/" +
                          props.vehiculo +
                          "/delete?token_fb=" +
                          window.FB.getAccessToken() +
                          "&token=" +
                          props.user.token,
                      })
                        .then((response) => {
                          setMemoCharge(false);
                          console.log(response.data);
                          props.refreshMovida(false);
                        })
                        .catch((error) => {
                          setMemoCharge(false);
                          reportar_error("fb", error.response.data.message);
                          props.refreshMovida(
                            true,
                            "error",
                            "Ooops...",
                            `${error.response.data.message}`,
                          );
                        });
                    });
                  });
                } else {
                  console.log("User cancelled login or did not fully authorize.");
                  props.refreshMovida();
                }
              });
            }
          });

          //////////////
        }
        break;
    }
  }

  /*
  if (initialValues.publish) {
    console.log("ESTO!@@#", initialValues.publish);
    Object.entries(initialValues.publish).map(function([key, value]) {
      // setPublish(publishsIns.set(key, value));
    });
  }*/
  const CheckBS = ({ input, label, chang, type, activado, id_vehicle, id }) => {
    const [estado, setEstado] = useState(activado);

    const setear = () => {
      setEstado(!estado);

      setTimeout(function () {
        chang(id, !estado);
        //console.log("|||VAMOS|||");
      }, 1000);
    };

    return (
      <div>
        <section className="slider-checkbox">
          <input {...input} checked={estado} onClick={setear} id={id} type={type} />
          <label className="label" for={id}>
            {label}
          </label>
        </section>
      </div>
    );
  };

  useEffect(() => {

    if (props.id_vehicle || props.prop.vehiculo || props.vehiculo) {
      setConfig({
        dealer_id: cookies.get("dealer").id,
        dealer_url: cookies.get("dealer").dealerUrl,
        vehicle_id: props.id_vehicle || props.prop.vehiculo || props.vehiculo
      });
    }

  }, [props.id_vehicle || props.prop.vehiculo || props.vehiculo]);

  const { showVehicle } = props;
  if (initialValues.publish) {
    return (
      <>
        <FacebookLibrary></FacebookLibrary>
        <PolyLoader open={MemoCharge} text="Espere un momento, por favor."></PolyLoader>
        <Modal open={enableFB} toggle={toglemovida}>
          <ModalHeader>Publicar en Facebook</ModalHeader>
          <ModalBody>
            <label>Elija la pagina con la que desea publicar el vehículo:</label>
            <select onChange={select_page} class="form-control">
              <option value="nulo">Seleccione</option>
              {pagesFB.map((p) => (
                <option value={JSON.stringify({ id: p.id, token: p.token })}>{p.note}</option>
              ))}
            </select>

            <br />

            <label>Copie y pegue lo que desee publicar:</label>
            <input
              class="form-control"
              disabled
              value={
                props.prop.showVehicle.brand_name +
                " - " +
                props.prop.showVehicle.model_name +
                " - " +
                props.prop.showVehicle.year +
                " - " +
                props.prop.showVehicle.kilometers +
                "km - Precio: " +
                props.prop.showVehicle.price
              }
            ></input>

            <br />
            <label>Escriba a continuacion lo que desea publicar:</label>
            <textarea
              max="2000"
              class="form-control"
              onChange={define_note}
              name="text"
              placeholder="Escriba aca"
            ></textarea>

            <br />

            <button class="btn btn-primary" onClick={subirFacebook}>
              Enviar
            </button>
          </ModalBody>
        </Modal>

        <Modal open={enableIN} toggle={toglemovidaIN}>
          <ModalHeader>Publicar en Instagram ..</ModalHeader>
          <ModalBody>
            <label>Elija la cuenta de instagram con la que desea publicar el vehículo:</label>
            <select onChange={select_pageIN} class="form-control">
              <option value="nulo">Seleccione</option>
              {pagesIN.map((p) => (
                <option value={JSON.stringify({ id: p.id, token: p.token })}>{p.note}</option>
              ))}
            </select>

            <br />

            <label>Copie y pegue lo que desee publicar:</label>
            <input
              class="form-control"
              disabled
              value={
                props.prop.showVehicle.brand_name +
                " - " +
                props.prop.showVehicle.model_name +
                " - " +
                props.prop.showVehicle.year +
                " - " +
                props.prop.showVehicle.kilometers +
                "km - Precio: " +
                props.prop.showVehicle.price

              }
            ></input>

            <br />

            <label>Escriba a continuacion lo que desea publicar:</label>
            <textarea
              max="2000"
              class="form-control"
              onChange={define_noteIN}
              name="text"
              placeholder="Escriba aca"
            ></textarea>

            <br />

            <button class="btn btn-primary" onClick={subirInstagram}>
              Enviar
            </button>
          </ModalBody>
        </Modal>


        <Card>
          <CardBody>
            <h4>Republicar</h4>
            <hr className="mb-4" />
            <Alert theme="light" className={`text-center ${colActive == true ? "hide" : ""}`}>
              Ya puedes publicar |
              <span
                className="font-weight-bol pontier text-decoration"
                onClick={() => {
                  deshacerConflicto(colActive); // cancelo la resolucion de conflicto
                }}
              >
                Cancelar resolucion de conflicto
              </span>
            </Alert>
            <Row style={{ justifyContent: "center" }}>
              <Col
                style={{ flex: "0 0 10.111%" }}
                className={`colPublish ${colActive === true || colActive === "ml" ? "csshow" : "csshide"
                  }`}
              >
                {initialValues.publish.mercadolibre == false ? (
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      margin: "4px",
                      opacity: "0.4",
                      boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                    }}
                    src="/img/meli.png"
                  />
                ) : (
                  <a target="_blank" href={initialValues.permalink_ml}>
                    <img
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        margin: "4px",
                        boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                      }}
                      src="/img/meli.png"
                    />
                  </a>
                )}

                <CheckBS
                  chang={changePublish}
                  activado={initialValues.publish.mercadolibre}
                  id="mercadolibre"
                  type="checkbox"
                ></CheckBS>
              </Col>
              <Col
                style={{ flex: "0 0 10.111%" }}
                className={`colPublish ${colActive === true || colActive === "ol" ? "csshow" : "csshide"
                  } ${colActive === "ol" ? "cssback" : ""}`}
              >
                {initialValues.publish.olx == false ? (
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      margin: "4px",
                      opacity: "0.4",
                      boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                    }}
                    src="/img/olx.png"
                  />
                ) : (
                  <>
                    {initialValues.status_olx == "active" ? (
                      initialValues.permalink_olx !== null ? (
                        <a
                          target="_blank"
                          href={
                            "https://www.olx.com.ar/profile/" + initialValues.permalink_olx.id_olx
                          }
                        >
                          <img
                            style={{
                              borderRadius: "5px",
                              width: "100%",
                              margin: "4px",
                              boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                            }}
                            src="/img/olx.png"
                          />
                        </a>
                      ) : (
                        <a target="_blank" href={"https://www.olx.com.ar/"}>
                          <img
                            style={{
                              borderRadius: "5px",
                              width: "100%",
                              margin: "4px",
                              boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                            }}
                            src="/img/olx.png"
                          />
                        </a>
                      )
                    ) : (
                      <a href="#">
                        <img
                          style={{
                            borderRadius: "5px",
                            width: "100%",
                            margin: "4px",
                            boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                          }}
                          src="/img/olxCharge.png"
                        />
                      </a>
                    )}
                  </>
                )}

                <CheckBS
                  chang={changePublish}
                  activado={initialValues.publish.olx}
                  id="olx"
                  type="checkbox"
                ></CheckBS>
              </Col>

              <Col
                style={{ flex: "0 0 10.111%" }}
                className={`colPublish ${colActive === true || colActive === "dm" ? "csshow" : "csshide"
                  }`}
              >
                {initialValues.publish.deMotores == false ? (
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      margin: "4px",
                      boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                      opacity: "0.4",
                    }}
                    src="/img/dm.png"
                  />
                ) : (
                  <a target="_blank" href={initialValues.permalink_dm}>
                    <img
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        margin: "4px",
                        boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                      }}
                      src="/img/dm.png"
                    />
                  </a>
                )}

                <CheckBS
                  activado={initialValues.publish.deMotores}
                  id="demotores"
                  type="checkbox"
                  chang={changePublish}
                ></CheckBS>
              </Col>
              <Col
                style={{ flex: "0 0 10.111%" }}
                className={`colPublish ${colActive === true || colActive === "lavoz" ? "csshow" : "csshide"
                  }`}
              >
                {initialValues.publish.lavoz == false ? (
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      margin: "4px",
                      boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                      opacity: "0.4",
                    }}
                    src="/img/lavoz.png"
                  />
                ) : (
                  <a target="_blank" href={initialValues.permalink_lavoz}>
                    <img
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        margin: "4px",
                        boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                      }}
                      src="/img/lavoz.png"
                    />
                  </a>
                )}

                <CheckBS
                  activado={initialValues.publish.lavoz}
                  id="lavoz"
                  type="checkbox"
                  chang={changePublish}
                ></CheckBS>
              </Col>
              <Col
                style={{ flex: "0 0 10.111%" }}
                className={`colPublish ${colActive === true || colActive === "ac" ? "csshow" : "csshide"
                  }`}
              >
                {initialValues.publish.autoCosmos == false ? (
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      margin: "4px",
                      opacity: "0.4",
                      boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                    }}
                    src="/img/autocosmos.png"
                  />
                ) : (
                  <a target="_blank" href={initialValues.permalink_ac}>
                    <img
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        margin: "4px",
                        boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                      }}
                      src="/img/autocosmos.png"
                    />
                  </a>
                )}

                <CheckBS
                  chang={changePublish}
                  activado={initialValues.publish.autoCosmos}
                  id="autocosmos"
                  type="checkbox"
                ></CheckBS>
              </Col>
              <Col
                style={{ flex: "0 0 10.111%" }}
                title="No se puede desactivar las publicaciones en NuestrosAutos.com.ar"
                className={`colPublish ${colActive === true || colActive === "na" ? "csshow" : "csshide"
                  }`}
              >
                <a target="_blank" href={"https://nuestrosautos.com.ar/detalle/" + props.vehiculo}>
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      margin: "4px",
                      boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                    }}
                    src="/img/na.png"
                  />
                </a>
                <div>
                  <section className="slider-checkbox">
                    <input checked={true} type="checkbox" />
                    <label className="label"></label>
                  </section>
                </div>
              </Col>
              <Col
                style={{ flex: "0 0 10.111%" }}
                title="El icono de WWW esta relacionado con el boton Habilitado de Mi Concesionaria. Para ejercer cualquier cambio sobre este, se debera hacer en mencionada seccion."
                className={`colPublish ${colActive === true || colActive === "na" ? "csshow" : "csshide"
                  }`}
              >
                {initialValues.car_dealers_public == 0 ? (
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      margin: "4px",
                      opacity: "0.4",
                      boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                    }}
                    src="/img/wwww.png"
                  />
                ) : (
                  <a
                    target="_blank"
                    href={
                      "https://" +
                      config.dealer_url +
                      ".nuestrosautos.cca.org.ar/" +
                      props.vehiculo +
                      " /" +
                      cookies.get("dealer").id +
                      "#true"
                    }
                  >
                    <img
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        margin: "4px",
                        boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                      }}
                      src="/img/wwww.png"
                    />
                  </a>
                )}

                <div>
                  <section id="toggleXd" className="slider-checkbox">
                    <input checked={initialValues.car_dealers_public == 1} type="checkbox" />
                    <label className="label"></label>
                    {initialValues.car_dealers_public == 0 && (
                      <Tooltip
                        open={toggleOpen}
                        target="#toggleXd"
                        toggle={() => setToogleOpen(!toggleOpen)}
                      >
                        <b>
                          El icono de WWW esta relacionado con el boton Habilitado de Mi
                          Concesionaria. Para ejercer cualquier cambio sobre este, se debera hacer
                          en mencionada seccion.
                        </b>
                      </Tooltip>
                    )}
                  </section>
                </div>
              </Col>

              <Col
                style={{ flex: "0 0 10.111%" }}
                className={`colPublish ${colActive === true || colActive === "fb" ? "csshow" : "csshide"
                  } ${colActive === "fb" ? "cssback" : ""}`}
              >
                {initialValues.publish.facebook == false ? (
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      margin: "4px",
                      opacity: "0.4",
                      boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                    }}
                    src="/img/facebook.png"
                  />
                ) : (
                  <a target="_blank" href={initialValues.permalink_fb}>
                    <img
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        margin: "4px",
                        boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                      }}
                      src="/img/facebook.png"
                    />
                  </a>
                )}

                <CheckBS
                  chang={changePublish}
                  activado={initialValues.publish.facebook}
                  id="facebook"
                  type="checkbox"
                ></CheckBS>
              </Col>
              <Col
                style={{ flex: "0 0 10.111%" }}
                className={`colPublish ${colActive === true || colActive === "in" ? "csshow" : "csshide"
                  } ${colActive === "in" ? "cssback" : ""}`}
              >
                {initialValues.publish.instagram == false ? (
                  <img
                    style={{
                      borderRadius: "5px",
                      width: "100%",
                      margin: "4px",
                      opacity: "0.4",
                      boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                    }}
                    src="/img/instagram.png"
                  />
                ) : (
                  <a target="_blank" href={initialValues.permalink_fb}>
                    <img
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        margin: "4px",
                        boxShadow: "-1px 4px 16px -11px rgba(0,0,0,0.75)",
                      }}
                      src="/img/instagram.png"
                    />
                  </a>
                )}
                <CheckBS
                  chang={changePublish}
                  activado={initialValues.publish.instagram}
                  id="instagram"
                  type="checkbox"
                ></CheckBS>
              </Col>

            </Row>

            <Modal open={olxTextModal == true} size={"lg"}>
              <ModalHeader>Publicar en OLX</ModalHeader>
              <ModalBody>
                <br />
                <Row>
                  <Col sm="7">
                    <label>Descripción de OLX:</label>
                    <textarea
                      max="2000"
                      class="form-control"
                      rows={6}
                      onChange={define_note_olx}
                      name="text"
                      placeholder="Escriba acá"
                    ></textarea>
                    {OLXtextValidation && <p className="textValidation">Ooops dato invalido!</p>}
                  </Col>
                  <Col sm="5">
                    <p>
                      OLX tiene ciertas restricciones a la hora de publicar. Especificamente en la
                      descripción, nos solicita que bajo ningúna circunstancia ingresemos los
                      siguientes datos en la misma:
                      <ul>
                        <li>Número Telefónicos</li>
                        <li>E-mails</li>
                        <li>Direciónes URL (Sitios Web)</li>
                      </ul>
                    </p>
                  </Col>
                </Row>
                <br />
                <button class="btn btn-primary mr-2" onClick={publicarOLX}>
                  Enviar
                </button>
                <button className="btn btn-secondary mr-2" onClick={() => setOlxTextModal(false)}>
                  Cancelar
                </button>
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
        <Drawer
          title="Basic Drawer"
          closable={false}
          placement="right"
          onClose={() => {
            setCatevisible(false);
          }}
          visible={catevisible}
        >
          <h5>Selecciona la categoria a publicar</h5>
          <hr />
          {cancelRC && (
            <Card id="resol" key="resol" className="mb-2">
              <CardBody>
                <h5 className="text-center">Resolución de Conflictos</h5>
                <Button
                  onClick={() => {
                    if (listingLaVoz == true) {
                      deshacerConflicto("lavoz");
                    } else {
                      deshacerConflicto("mercadolibre");
                    }
                  }}
                  outline
                  className="w-100"
                  theme="danger"
                >
                  Cancelar
                </Button>
              </CardBody>
            </Card>
          )}

          {listingLaVoz == true && (
            <Card key={"cat" + 1} id={"oneandone"} className="mb-2">
              <CardBody>
                <h5 className="text-center">Gratis</h5>
                <Button
                  onClick={() => {
                    publishLaVoz(1);
                  }}
                  outline
                >
                  Gratis
                </Button>
              </CardBody>
            </Card>
          )}

          {deAutosListingTypes == true && (
            <>
              <Card key={"cat" + 2} id={"oneandonefree"} className="mb-2">
                <CardBody>
                  <h5 className="text-center">Gratis</h5>
                  <Button
                    onClick={() => {
                      publishDA("G");
                    }}
                    outline
                    className="w-100"
                  >
                    Elegir
                  </Button>
                </CardBody>
              </Card>

              <Card key={"cat" + 3} id={"oneandoneclassic"} className="mb-2">
                <CardBody>
                  <h5 className="text-center">Clásico</h5>
                  <Button
                    onClick={() => {
                      publishDA("C");
                    }}
                    outline
                    className="w-100"
                  >
                    Elegir
                  </Button>
                </CardBody>
              </Card>

              <Card key={"cat" + 4} id={"oneandonepremium"} className="mb-2">
                <CardBody>
                  <h5 className="text-center">Premium</h5>
                  <Button
                    onClick={() => {
                      publishDA("P");
                    }}
                    className="w-100"
                    outline
                  >
                    Elegir
                  </Button>
                </CardBody>
              </Card>

              <Card key={"cat" + 5} id={"oneandonesuperpremium"} className="mb-2">
                <CardBody>
                  <h5 className="text-center">Super Premium</h5>
                  <Button
                    onClick={() => {
                      publishDA("SP");
                    }}
                    outline
                    className="w-100"
                  >
                    Elegir
                  </Button>
                </CardBody>
              </Card>
            </>
          )}

          {listingTypes &&
            listingTypes.map(function (a, v) {
              console.log(a);
              if (listingLaVoz == true) {
                return (
                  <Card key={"cat" + a.nid} id={"cat" + a.nid} className="mb-2">
                    <CardBody>
                      <h5 className="text-center">{a.nombre}</h5>
                      <Button
                        onClick={() => {
                          publishLaVoz(a.nid);
                        }}
                        outline
                      >
                        Elegir
                      </Button>
                    </CardBody>
                  </Card>
                );
              } else {
                if (a.id != null && a.id != undefined) {
                  return (
                    <Card key={"cat" + a.id} className="mb-2">
                      <CardBody>
                        <h5 className="text-center">{a.name}</h5>
                        <Button
                          onClick={() => {
                            {
                              listingLaVoz == true ? publishLaVoz(a.id) : publishML(a.id);
                            }
                          }}
                          outline
                          className="w-100"
                        >
                          Elegir
                        </Button>
                      </CardBody>
                    </Card>
                  );
                }
              }
            })}
        </Drawer>
        <Drawer
          title="Resolucion de conflicto"
          width="45%"
          onClose={() => {
            setConflict(false);
          }}
          visible={conflict}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {configConflict && (
            <ResolveConflict
              dataConf={configConflict}
              close={() => {
                setConflict(false);
              }}
              visible={(e) => {
                setColActive(e);
              }}
              backLaVoz={() => {
                axios({
                  method: "get",
                  url:
                    API_URL +
                    "api/lavoz/listing-types/" +
                    cookies.get("dealer").id +
                    "?token=" +
                    props.user.token,
                })
                  .then((response) => {
                    setListingTypes([]);
                    setListingTypes(response.data.ms.destaques);
                    setCatevisible(true);
                    setListingLaVoz(true);
                    setMemoCharge(false);
                    setCancelRC(true);
                    setCatevisible(true);
                  })
                  .catch((err) => {
                    openNotificationWithIcon(
                      "error",
                      "Ocurrio un problema",
                      `${err.response.data}`,
                    );
                    setMemoCharge(false);
                    //props.refreshMovida();
                    // console.log('|ERRRIRIR ||',err.response.data);
                  });
              }}
              cancelRc={() => {
                setCancelRC(true);
                setCatevisible(true);
              }}
            ></ResolveConflict>
          )}
        </Drawer>
      </>
    );
  } else {
    return (
      <div>
        <p>Cargando</p>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { users, authentication, vehicles, alert, notifications } = state;

  const { user } = authentication;
  const { showVehicle, id_vehicle } = vehicles;

  return { user, users, showVehicle, alert, id_vehicle, notifications };
}

const actionCreators = {
  //showVehicleID: vehiclesActions.showVehicle
  showAlert: alertActions.show,
  hideAlert: alertActions.hide,
  getNoti: notificacionsActions.getNotifications,
};

const connectedPublishInput = connect(mapStateToProps, actionCreators)(PublishInput);

export { connectedPublishInput as PublishInput };
